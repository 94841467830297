import { createSlice } from '@reduxjs/toolkit'

export const sessionTimeoutSlice = createSlice({
  name: 'sessionTimeout',
  initialState: {
    value: false,
  },
  reducers: {
    changeState: (state) => {
      state.value = !state.value;
    },
    //changeState:  (state, action) => {
      //state.value = action.payload
    //},
  },
})

export const { changeState } = sessionTimeoutSlice.actions


export const selectSessionTimeout = (state) => state.sessionTimeout.value

export default sessionTimeoutSlice.reducer
