import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { formApi, pushToError } from '.../utils/apiHelper';
import { ElementInitializationContext } from './ElementInitializationContext';
import ElementInfoObject from './ElementInitializationContext';
import FormEditorErrorBar from './FormEditorErrorBar';
import FormEditorGrid from './FormEditorGrid';
import FormEditorHeader from './FormEditorHeader';

const styles = theme => ({
    container: {
        '& .Mui-disabled': {
            color: `${theme.palette.text.primary}`
        },
        '& .react-grid-layout': {
            transition: 'none'
        },
        '& .react-grid-item': {
            transition: 'none',
            transitionProperty: 'none'
        }
    }
});

class FormEditorContainer extends React.Component {
    state = {
        formUnsavedChanges: false,
        validationLoading: false,
        schemaValidation: null,
        schema: null,
        schemaLoading: false,
        eventTables: null,
        eventTablesLoading: true,
        formList: null,
        formListLoading: true,
        views: null,
        viewsLoading: true,
        newCounter: 0,
        isFormDisabled: false,
        elementInitializationInfo: new ElementInfoObject((newElementInitializationInfo) => {
            this.setState({
                elementInitializationInfo: newElementInitializationInfo
            });
        })
    }

    componentDidMount() {
        const { patientInfo } = this.props;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        formApi.getEventTables().then(eventTables => {
            this.setState({
                eventTables: eventTables.map(eventTable => ({ eventTable })),
                eventTablesLoading: false
            });
        }).catch(error => pushToError(error, patientId));
        formApi.getFormList().then(formList => {
            this.setState({
                formList,
                formListLoading: false
            });
        }).catch(error => pushToError(error, patientId));
        formApi.getViewObjects().then(views => {
            this.setState({
                views,
                viewsLoading: false
            });
        }).catch(error => pushToError(error, patientId));
   }

    componentDidUpdate = () => {
        const { formUnsavedChanges } = this.state;
       /*  if (formUnsavedChanges) {
            window.onbeforeunload = () => 'You have unsaved changes, are you sure you want to leave this page?';
        } else {
            window.onbeforeunload = undefined;
        } */
    }

    componentWillUnmount() {
        window.onbeforeunload = undefined;
    }

    updateDisplaySize = (displaySize) => () => {
        const { elementInitializationInfo } =  this.state;
        elementInitializationInfo.displaySize = displaySize;
        this.setState({
            elementInitializationInfo
        });
    }

    validateFormSchema = (fullSchema) => () => {
        if (fullSchema !== null) {
            const { patientInfo } = this.props;
            let patientId = patientInfo === null ? undefined : patientInfo.patientId;

            this.setState({
                validationLoading: true
            });
            
            return formApi.validateFormSchema(fullSchema).then(schemaValidation => {
                this.setState({
                    schemaValidation,
                    validationLoading: false
                });

                return schemaValidation;
            }).catch(error => pushToError(error, patientId));
        }
    }

    saveForm = (headerSchema) => {
        const { patientInfo } = this.props;
        const { schema } = this.state;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        return formApi.saveFormSchema(schema).then(formName => {
            //TODO This set state call may not be working due to a binding issue (?), and consequently, everything within this promise may be failing.
            this.setState({
                schema: schema,
                formUnsavedChanges: false,
                isFormDisabled:false
            });

            formApi.getFormList().then(formList => {
                this.setState({
                    formList,
                    formListLoading: false
                });
            this.checkFormAttributes(schema.header.formName, patientId);
            }).catch(error => pushToError(error, patientId));

            return schema.header.formName;
        });
    }

    deleteForm = (schema) => {
        const { patientInfo } = this.props;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        return formApi.deleteFormSchema(schema.header.formName).then(() => {
            this.loadFormSchema(null);
            formApi.getFormList().then(formList => {
                this.setState({
                    formList,
                    formListLoading: false
                });
            }).catch(error => pushToError(error, patientId));
        });
    }

    copyForm = () => {
        const { schema } = this.state;

        let newSchema = {
            ...schema,
            header: {
                ...schema.header,
                formName: uuidv4(),
                formDisplayName: `${schema.header.formDisplayName} - Copy`
            },
            sections: [
                ...schema.sections
            ]
        };
        this.setState({
            formUnsavedChanges: true,
            schema: newSchema,
            isFormDisabled:false
        });
    };

    createNewForm = () => {
        const { elementInitializationInfo } = this.state;
        elementInitializationInfo.formMetaData = {};
        this.setState({
            formUnsavedChanges: true,
            schema: {
                formTitle: '',
                header: {
                    formName: uuidv4(),
                    formDisplayName: '',
                    viewName: null,
                    eventTable: null,
                    sequence: 0
                },
                sections: []
            },
            elementInitializationInfo
        });
    }

    setFormSchema = (fullSchema) => {
        const { elementInitializationInfo } = this.state;

        elementInitializationInfo.formMetaData = fullSchema.metaData;
        this.setState({
            schema: fullSchema.schema,
            elementInitializationInfo,
            schemaLoading: false
        }, this.validateFormSchema(fullSchema));
    }

    loadFormSchema = (formName) => {
        const { patientInfo } = this.props;
        const { elementInitializationInfo } = this.state;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;
        elementInitializationInfo.formMetaData = {};
        if (formName === null) {
            this.setState({
                formUnsavedChanges: false,
                schemaValidation: null,
                schema: null,
                elementInitializationInfo
            });
        } else {
            this.setState({
                formUnsavedChanges: false,
                schemaValidation: null,
                schema: null,
                elementInitializationInfo: elementInitializationInfo,
                schemaLoading: true
            });
            formApi.getFormSchema(formName)
                .then(this.setFormSchema)
                .catch(error => pushToError(error, patientId));   
            this.checkFormAttributes(formName, patientId);   
            }

    }
    checkFormAttributes = (formName, patientId) => {
        formApi.getFormNameAttribute(formName).then(isFormEditorLock => {
            if (isFormEditorLock) {
                this.setState({
                    isFormDisabled: true
                });
            } else {
                this.setState({
                    isFormDisabled: false
                });
            }
        }).catch(error => pushToError(error, patientId));
    }
    updateFormTitle = (formDisplayName) => {
        const { schema } = this.state;
        let newSchema = { ...schema, header: { ...schema.header } };

        newSchema.formTitle = formDisplayName;
        newSchema.header.formDisplayName = formDisplayName;

        this.setState({
            schema: newSchema,
            formUnsavedChanges: true
        });
    }

    updateEventTable = (eventTable) => {
        const { schema, elementInitializationInfo } = this.state;
        let newFullSchema = {
            schema: {
                ...schema,
                header: { ...schema.header }
            },
            metaData: elementInitializationInfo.formMetaData
        };

        newFullSchema.schema.header.eventTable = eventTable ? eventTable.eventTable : null;
        newFullSchema.schema.header.viewName = null;
        this.validateFormSchema(newFullSchema)();

        this.setState({
            schema: newFullSchema.schema,
            formUnsavedChanges: true
        });
    }

    updateView = (view) => {
        const { schema, elementInitializationInfo } = this.state;
        let newFullSchema = {
            schema: {
                ...schema,
                header: { ...schema.header }
            },
            metaData: elementInitializationInfo.formMetaData
        };

        newFullSchema.schema.header.viewName = view ? view.viewName : null;
        newFullSchema.schema.header.eventTable = view ? view.tableName : newFullSchema.schema.header.eventTable;

        //elementInitialization will call multiple set states as the view changes, may be overkill since we call it in this method as well.
        elementInitializationInfo.setViewName(view);
        this.validateFormSchema(newFullSchema)();

        this.setState({
            schema: newFullSchema.schema,
            formUnsavedChanges: true
        });
    }

    handleHeaderPropertyChange = (newFormHeader) => {
        const { schema } = this.state;
        schema.header = newFormHeader;
        this.setState({
            schema
        });
    }

    updateSectionLayout = (layouts) => {
        const { schema } = this.state;
        let newSchema = { ...schema, sections: [...schema.sections.map(section => ({ ...section }))] };

        layouts.sort((a, b) => (a.y > b.y) ? 1 : -1);
        let sequenceOrder = 0;
        layouts.forEach(layout => {
            const { i } = layout;
            let sectionIndex = parseInt(i);
            newSchema.sections[sectionIndex].sequence = sequenceOrder;
            sequenceOrder += 1;
        });

        this.setState({
            schema: newSchema,
            formUnsavedChanges: true
        });
    }

    updateSectionHide = (section, hide) => {
        section.hide = hide;
        this.handleSectionUpdate(section);
    }
    
    updateSectionDisplayName = (section, sectionDisplayName) => {        
        section.sectionDisplayName = sectionDisplayName;
        this.handleSectionUpdate(section);
    }    

    onAddSection = () => {
        const { schema } = this.state;
        let newSchema = { ...schema, sections: [...schema.sections.map(section => ({ ...section }))] };

        newSchema.sections.push({
            sectionName: uuidv4(),
            sectionDisplayName: `Section #${newSchema.sections.length + 1}`,
            sequence: newSchema.sections.length,
            numberOfItems: 0,
            elements: [],
            subSections: []
        });

        this.setState({
            schema: newSchema,
            formUnsavedChanges: true
        });
    }

    updateSectionUids = (section) => {
        section.sectionName = uuidv4();
        if (section && Array.isArray(section.subSections)) {
            section.subSections.forEach(subSection => this.updateSectionUids(subSection));
        }
        return section;
    };

    onImportSection = (importedSection) => {
        const { schema } = this.state;
        const { patientInfo } = this.props;
        let patientId = patientInfo ? patientInfo.patientId : undefined;

        let newSchema = { ...schema, sections: [...schema.sections] };
        importedSection.sequence = newSchema.sections.length;
        this.updateSectionUids(importedSection);

        newSchema.sections.push(importedSection);

        formApi.getFullFormSchemaFromSchema(newSchema)
            .then(this.setFormSchema)
            .catch(error => pushToError(error, patientId));
    };

    onImportSubSection = (sectionSchema) => {
        const { patientInfo } = this.props;
        const { schema } = this.state;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        let newSchema = { ...schema, sections: [...schema.sections.map(section => ({ ...section }))] };

        let i = newSchema.sections.findIndex(s => s.sectionName === sectionSchema.sectionName);
        newSchema.sections[i] = sectionSchema;

        formApi.getFullFormSchemaFromSchema(newSchema)
            .then(this.setFormSchema)
            .catch(error => pushToError(error, patientId));
    };

    onRemoveSection = (sectionIndexString) => () => {
        const { schema, elementInitializationInfo } = this.state;
        let newFullSchema = {
            schema: {
                ...schema,
                header: { ...schema.header }
            },
            metaData: elementInitializationInfo.formMetaData
        };

        let sectionIndex = parseInt(sectionIndexString);

        newFullSchema.schema.sections.splice(sectionIndex, 1);

        this.setState({
            schema: newFullSchema.schema,
            formUnsavedChanges: true
        });

        this.validateFormSchema(newFullSchema)();
    }

    handleSectionUpdate = (newSection) => {
        const { schema } = this.state;

        let newSchema = {
            ...schema,
            sections: [
                ...schema.sections
            ]
        };
        let sectionIndex = newSchema.sections.findIndex((section) => section.sectionName === newSection.sectionName);
        newSchema.sections.splice(sectionIndex, 1, newSection);

        this.setState({
            schema: newSchema
        });
    }

    render() {
        const { classes } = this.props;
        const {
            schemaLoading, schema,
            elementInitializationInfo,
            formListLoading, formList,
            eventTablesLoading, eventTables,
            viewsLoading, views,
            validationLoading, schemaValidation,
            formUnsavedChanges,
            isFormDisabled
        } = this.state;

        if (formListLoading || viewsLoading || eventTablesLoading) {
            return <CircularProgress size={60} thickness={7} />;
        }

        let schemaNotInFormList = formList.map(header => header.formName.toLowerCase() ?? '').indexOf(schema?.header.formName) === -1;

        return (
            <div className={classes.container} style={{maxHeight: "calc(100% + 25px)", overflow:'auto'}}>
                <FormEditorHeader
                    validationLoading={validationLoading}
                    onHeaderPropertyChange={this.handleHeaderPropertyChange}
                    schemaValidation={schemaValidation}
                    validateFormSchema={this.validateFormSchema(schema)}
                    saveForm={this.saveForm}
                    deleteForm={this.deleteForm}
                    copyForm={this.copyForm}
                    createNewForm={this.createNewForm}
                    loadFormSchema={this.loadFormSchema}
                    formList={schema !== null && schemaNotInFormList ?
                        [...formList, schema.header]
                        :
                        formList
                    }
                    schema={schema}
                    updateFormTitle={this.updateFormTitle}
                    updateEventTable={this.updateEventTable}
                    eventTables={eventTables}
                    views={views}
                    updateView={this.updateView}
                    displaySize={elementInitializationInfo.displaySize}
                    updateDisplaySize={this.updateDisplaySize}
                    formUnsavedChanges={formUnsavedChanges}
                />
                <FormEditorErrorBar
                    setFormSchema={this.setFormSchema}
                    validationLoading={validationLoading}
                    schemaValidation={schemaValidation}
                />
                {!schemaLoading ? null :
                    <CircularProgress size={60} thickness={7} />
                }
                {schema === null ? null :
                    <ElementInitializationContext.Provider value={elementInitializationInfo}>
                        <FormEditorGrid
                            schemaValidation={schemaValidation}
                            validateFormSchema={this.validateFormSchema(schema)}
                            schema={schema}
                            updateSectionLayout={this.updateSectionLayout}
                            updateSectionDisplayName={this.updateSectionDisplayName}
                            updateSectionHide={this.updateSectionHide}
                            onAddSection={this.onAddSection}
                            onImportSection={this.onImportSection}
                            onRemoveSection={this.onRemoveSection}
                            onUpdateSection={this.handleSectionUpdate}
                            elementInitializationInfo={elementInitializationInfo}
                            displaySize={elementInitializationInfo.displaySize}
                            onImportSubSection={this.onImportSubSection}
                            updateSectionUids={this.updateSectionUids}
                            disabled={isFormDisabled}
                        />
                    </ElementInitializationContext.Provider>
                }
                <Prompt when={formUnsavedChanges} message='You have unsaved changes, are you sure you want to leave this page?' />
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(connect((state) => ({ patientInfo: state.demographics }))(FormEditorContainer)));