import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import ValidationErrorIcon from '.../components/formComponents/helperComponents/ValidationErrorIcon';
import FieldInfoButton from ".../components/formComponents/helperComponents/FieldInfoButton";
import LxCombobox from '.../components/formComponents/LxCombobox';

const styles = theme => ({
    seekableDiv: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexWrap: "nowrap"
    }
});

class MultiColumnCombo extends React.Component {
    constructor(props) {
        super(props);
        this.elementRef = React.createRef();
        this.state = {
            originalValue: MultiColumnCombo.getDataPoint(this.props).value,
            internalValue: MultiColumnCombo.getDataPoint(this.props).value
        };
    }

    downshiftRef = null

    getDownshiftRef = (ref) => this.downshiftRef = ref

    static getDataPoint = (props) => {
        const { formData, recordContext, itemSchema, dataPoint } = props;
        if (dataPoint) {
            return dataPoint;
        } else if (formData && recordContext) {
            return {
                ...recordContext.getDataPoint(formData, itemSchema.tableName, itemSchema.fieldName)
            };
        } else{
            return {
                value: '',
                options: []
            };
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let dataValue = MultiColumnCombo.getDataPoint(props).value;
        if (dataValue ? dataValue : '' !== state.originalValue) {
            return ({
                originalValue: dataValue ? dataValue : '',
                internalValue: dataValue ? dataValue : ''
            });
        }

        return null;
    }

    handleSearchChange = (event) => {
        this.setState({
            internalValue: event.target.value
        });
    }

    handleChange = (item) => {
        const { itemSchema, recordContext, onBlur, clearHighlightedField } = this.props;
        let dataPoints = [];

        if (typeof onBlur === 'function') {
            if (itemSchema.sourceColumns) {
                itemSchema.sourceColumns.forEach(column => {
                    if (column.targetTable && column.targetField) {
                        let value;
                        let currentKey = recordContext.recordContext[column.targetTable].key;
                        if (item) {
                            value = item[column.fieldName];
                        } else {
                            value = null;
                        }
                        dataPoints.push({
                            keyValue: currentKey,
                            tableName: column.targetTable,
                            FieldName: column.targetField,
                            value: value
                        });
                        if(column.TargetTable === itemSchema.TableName
                            && column.TargetField === itemSchema.FieldName) {
                            this.setState({
                                internalValue: value
                            });
                        }
                    }
                });
            }
            onBlur(dataPoints);
        }
        if (typeof clearHighlightedField === 'function' && this.isHighlighted()) {
            clearHighlightedField();
        }
    }

    handleBlur = (event, selectedItem) => {
        const { internalValue, originalValue, clearHighlightedField } = this.state;
        if (internalValue !== originalValue)
        {
            this.setState({
                internalValue: originalValue
            });
        }
        if (typeof clearHighlightedField === 'function' && this.isHighlighted()) {
            clearHighlightedField();
        }
    }

    itemToString = (item) => {
        const { itemSchema } = this.props;
        let retval = '';
        if (item && itemSchema.sourceColumns) {
            itemSchema.sourceColumns.forEach(column => {
                if (column.targetTable === itemSchema.tableName
                    && column.targetField === itemSchema.fieldName) {
                    retval = item[column.fieldName];
                }
            });
        }
        return retval;
    }

    buildColumnList = () => {
        const { itemSchema } = this.props;

        let columnList = [];
        if (itemSchema.sourceColumns) {
            itemSchema.sourceColumns.forEach((column) => {
                if (!column.hidden) {
                    columnList.push({
                        title: column.displayName ? column.displayName : column.fieldName,
                        key: column.fieldName
                    });
                }
            });
        }

        return columnList;
    }

    isHighlighted = () => {
        const { itemSchema, highlightedFieldKeyArray } = this.props;
        let retval = false;
        if (Array.isArray(highlightedFieldKeyArray) && highlightedFieldKeyArray.length > 0) {
            var fieldKeyArrayCopy = [...highlightedFieldKeyArray];
            let firstKey = fieldKeyArrayCopy.shift();
            if (firstKey === itemSchema.uniqueKey) {
                let secondKey = fieldKeyArrayCopy.shift();
                let mainKey = `${itemSchema.tableName}.${itemSchema.fieldName}`;
                if (secondKey === mainKey) {
                    return true;
                }
                itemSchema.sourceColumns.forEach((column) => {
                    let columnKey = `${column.targetTable}.${column.targetField}`;
                    if (columnKey === secondKey) {
                        retval = true;
                    }
                });
            }
        }
        return retval;
    }

    render() {
        const { itemSchema, itemMetaData, formData, disabled, triggerScroll, hideLabel, classes, formMetaData, viewName } = this.props;
        const { internalValue } = this.state;

        let columns = this.buildColumnList();
        let dataPoint = MultiColumnCombo.getDataPoint(this.props);
        let dataSources = formData?.lookupDataSources;
        let dataSource = dataSources ? dataSources[itemSchema.uniqueKey] : [];
        let calculatedDisabled = itemSchema.readOnly || itemMetaData?.forcedReadOnly || disabled || !dataPoint.isValidChild || !(Array.isArray(dataSource) && dataSource.length > 0);
        let labelText = itemSchema.displayName ? itemSchema.displayName : `${itemSchema.tableName}.${itemSchema.fieldName}`;
        let highlighted = this.isHighlighted();

        let descriptions = formMetaData ? (formMetaData[itemSchema.metaDataKey]) !== undefined ? formMetaData[itemSchema.metaDataKey].descriptions: [] : [];
        let fieldInformation = formMetaData ? (formMetaData[itemSchema.metaDataKey])!==undefined?formMetaData[itemSchema.metaDataKey].fieldInformation:[] : [];
        let fieldInfo = [itemSchema.tableName, itemSchema.fieldName, viewName];

        if (highlighted) {
            if (this.elementRef.current) {
                this.elementRef.current.scrollIntoView();
                triggerScroll(this.elementRef.current);
            }
        }

        return (
            <div className={classes.seekableDiv} ref={this.elementRef}>
                <LxCombobox
                    disabled={calculatedDisabled}
                    title={hideLabel ? undefined : labelText}
                    description={Array.isArray(itemMetaData?.descriptions) && itemMetaData?.descriptions.length > 0 ? itemMetaData?.descriptions[0].description : undefined}
                    hasMappings={Array.isArray(itemMetaData?.emrMappings) && itemMetaData?.emrMappings.length > 0}
                    //TODO still does not have support for SELECTING mapping values, just displaying if there are mappings for the field. 
                    //Unknown how to integrate EMR Mappings with a tightly controlled selection component like this.
                    items={dataSource ? dataSource : []}
                    itemToString={this.itemToString}
                    inputDisplayTemplate={this.itemToString}
                    inputValue={internalValue}
                    columns={columns}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    keepInputOnExit
                    fitInputWidth
                    downshiftRef={this.getDownshiftRef}
                    placeholder='Select Value...'
                    onChangeInput={this.handleSearchChange}
                    tabIndex={disabled ? -1 : itemSchema.tabIndex}
                    autoFocus={itemSchema.tabIndex === 1}
                    clearFilterOnOpen
                    highlighted={highlighted}
                    forcedFocus={highlighted}
                    titleTooltip={`${itemSchema.tableName}.${itemSchema.fieldName}`}
                />
                {(fieldInformation) ?
                    <FieldInfoButton
                        descriptions={descriptions}
                        fieldInformation={fieldInformation}
                        fieldInfo={fieldInfo} />
                    :
                    null
                }
                <ValidationErrorIcon
                    error={!calculatedDisabled && dataPoint.validationError !== null}
                    validationErrorSeverity={dataPoint.validationErrorSeverity}
                    helperText={!calculatedDisabled ? dataPoint.validationError : null}
                />
            </div>
        );
    }
}

export default withStyles(styles)(MultiColumnCombo);