import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Button } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import useIdle from '.../utils/useIdleTimer';
import {SESSIONTIMEOUT_ENABLED, IDLESESSION_TIMEOUT, IDLESESSION_TIMEOUTPROMPTTIME} from ".../components/settings/SettingKeys";

//const autologoutEnable = window.env.AutologoutEnable;
const styles = theme =>({
    cardHeader: {
        padding: '2px'
    },
    card: {
        marginTop: '35px',
        backgroundColor: '#3886C9'
    },
    avatar: {
        marginTop: '1px',
        marginLeft: '5px',
        width: '25px',
        height: '25px'
    },
    buttonStyle: {
        border: `1px solid ${theme.palette.lxGreen.main}`,
        backgroundColor: theme.palette.lxGreen.light,
        color: 'white',
        margin: 'auto',
        marginTop: '10px',
        marginBottom: '2px',
        marginRight: '15px',
        verticalAlign: 'middle'
    },    
    bodyText: {
        marginTop: '1px',
        marginLeft: '2px',
        textAlign: 'left'
    },
    paper: {
        margin: `${theme.spacing(1)}px`,
        width: '97%',
        height: `calc(105vh - 100px)`,
        overflowY: 'auto',
        MsOverflowStyle: 'scrollbar'
    },
    backendActionBackdrop: {
        zIndex: '10000'
    },
    dialogPaper: {
        width: '100%'
    },
    dialogButtons:{
        justifyContent:"center"
    }
});

function IdleSession({...props})  {    
        const {classes, settings} = props;
        const autologoutEnable = settings[SESSIONTIMEOUT_ENABLED];
        const idleTime = settings[IDLESESSION_TIMEOUT];
        const promptTime = settings[IDLESESSION_TIMEOUTPROMPTTIME];
        //const {  classes } = this.props;
        const timeout = 1000 * 60 * idleTime;
        const [remaining, setRemaining] = useState(timeout)
        const [openModal, setOpenModal] = useState(false)
       
    const handleOnIdle = () => {
        if (autologoutEnable) {
            setOpenModal(true);
        }
        else {
            setOpenModal(false);
        }
    };
        
    const { getRemainingTime,

        activate
    } = useIdle({ onIdle: handleOnIdle, idleTime: idleTime, autologoutEnable: autologoutEnable, promptTime: promptTime });
        
    const stay = () => {
        setOpenModal(false);
        activate();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval)
        };
    });

        return (
            <>
             {/* {openModal && <Card className={classes.card} >
                <CardHeader className={classes.cardHeader}
                    avatar={
                        <Avatar className={classes.avatar}>
                            i
                        </Avatar>
                    }
                    subheader={
                        <Typography variant="body1" className={classes.bodyText}>
                            Your are idle from last {idleTime} mins., Your session will get expired in next {remaining} seconds. Click on Stay to continue this session
                        </Typography>
                    }
                    action={
                        <>
                        <Button className={classes.buttonStyle} onClick={stay} >
                            <Typography variant='body2'>
                                Stay
                            </Typography>
                        </Button>
                        
                        
                    </>
                    }
                    
                    
                />
            </Card>} */}

            <Dialog open={openModal} classes={{ paper: classes.dialogPaper }} maxWidth={'sm'} disableBackdropClick>
                   
                    <DialogTitle className={classes.dialogTitle}>
                        {'Warning, Idle Session'}
                    </DialogTitle>
                    <DialogContent >
                        {'Due to inactivity. Medical Co-Pilot will automatically log you out in '}{remaining}{' seconds.'}{ <br />} {'Press Cancel to keep the application from logging you out'}
                    </DialogContent>
                    <MuiDialogActions className={classes.dialogButtons} >
                        <Button variant='contained' color='primary' onClick={stay}>
                            {'Cancel'}
                        </Button>
                    </MuiDialogActions>
            </Dialog>
            </>
           
        );
    
}
export default withStyles(styles)(IdleSession);