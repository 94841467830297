import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import DemographicsReducer from './DemographicsReducer';
import RecordReducer from './RecordReducer';
import STSSurgicalRiskReducer from './STSSurgicalRiskReducer';
import timeoutReducer from './SessionTimoutSlice';


const Reducer = combineReducers(
    {
        routing: routerReducer,
        stsSurgicalRisk: STSSurgicalRiskReducer,
        demographics: DemographicsReducer,
        recordInfo: RecordReducer,
        sessionTimeout: timeoutReducer
    }
);

export default Reducer;
