import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const styles = theme => ({
    dividerLineTitle: {
        display: 'flex',
        alignItems: 'center',
        margin: `${theme.spacing(1)}px 0px`,
        width: '100%'
    },
    dividerTitle: {
        flex: '0 0 auto',
        marginRight: `${theme.spacing(2)}px`
    },
    dividerLine: {
        flex: '1 0 auto'
    }
});

class LabelElement extends React.Component {

    render() {
        const { classes, itemSchema } = this.props;      
                
        return (
            <div className={classes.dividerLineTitle}>                
            {itemSchema && itemSchema.style ? (
            <>
            <Typography style={itemSchema.style} align="left" variant="body2">
                {itemSchema.text ?? ''}
            </Typography>

            {itemSchema.style.textLineVisible ? (
            <><Divider className={`${classes.cardDivider} ${classes.dividerLine}`} /></>
            ) : null}
            </>
            ) : 
            <>
            <Typography style={itemSchema.style} align="left" variant="body2">
                {itemSchema.text ?? ''}
            </Typography>           
            <Divider className={`${classes.cardDivider} ${classes.dividerLine}`} />
            </>} 
            </div>
        );
    
    }
}

export default withStyles(styles)(LabelElement);