import { Dialog, DialogContent } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Close from '@material-ui/icons/Close';
import React from 'react';
import moment from 'moment';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import ContextManager from ".../components/features/forms/ContextManager";
import DynamicForm from '.../components/features/forms/DynamicForm';
import DynamicFormSection from '.../components/features/forms/DynamicFormSection';
import FormControlBar from '.../components/features/forms/FormControlBar';
import FormsIllegalDataDialog from ".../components/features/forms/FormsIllegalDataDialog";
import FormViewOnlyBar from '.../components/features/forms/FormViewOnlyBar';
import StaffContainer from '.../components/features/staff/StaffContainer';
import mediaQueries from '.../utils/mediaQueries';
import ResultContainer from './ResultContainer';
import Typography from '@material-ui/core/Typography';
import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';

const styles = theme => ({
    paper: {
        margin: `${theme.spacing(1)}px`,
        width: '97%',
        height: `calc(100vh - 100px)`,
        overflowY: 'auto',
        MsOverflowStyle: 'scrollbar'
    },
    cardBody: {
        padding: '150px'
    },
    sidebarEdit: {
        width: '250px',
        position: 'fixed',
        top: `${theme.spacing(17)}px`,
        maxHeight: '85vh',
        overflowY: 'auto',
        [mediaQueries.breakpointPhone]: {
            position: 'inherit'
        },
        '& .coefficientList': {
            [mediaQueries.breakpointPhone]: {
                overflowY: 'visible',
                maxHeight: 'none'
            }
        }
    },
    sidebarGrid: {
        width: '250px',
        marginRight: '8px',
        [mediaQueries.breakpointPhone]: {
            marginRight: '0px',
            marginBottom: '56px',
        }
    },
    sidebar: {
        width: '250px',
        position: 'fixed',
        top: `${theme.spacing(29)}px`,
        maxHeight: '85vh',
        overflowY: 'auto',
        [mediaQueries.breakpointPhone]: {
            position: 'inherit'
        },
        '& .coefficientList': {
            [mediaQueries.breakpointPhone]: {
                overflowY: 'visible',
                maxHeight: 'none'
            }
        }
    },
    patientInfo: {
        fontSize: '13px',
        fontWeight: 'normal',
        paddingLeft: "5px",
        paddingTop: "10px"
    },
    formGrid: {
        flex: '1 1 100%',
        maxWidth: 'calc(100% - 258px)',
        height: 'calc(100%)-10px',
        [mediaQueries.breakpointPhone]: {
            maxWidth: '100%',
            marginBottom: '56px'
        },
        overflowY: 'hidden'
    },
    mobileTabs: {
        position: 'fixed',
        bottom: '0px',
        left: '0px',
        width: '100%',
        zIndex: '1000',
        backgroundColor: 'white',
        borderTop: '2px solid #d5d5d5'
    },
    mobileTab: {
        width: '50%'
    },
    progressBar: {
        marginTop: `10px`,
        width: '100%'
    },
    backendActionBackdrop: {
        zIndex: '10000'
    },
    cardWidth: {
        width: '100%'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    reportButtonPosition: {
        float: "right"
    },
    dialogPaper: {
        width: '100%'
    },
    EpicConfirmationDialogContent: {
        textAlign: 'left'
    }
});

class StsSurgicalRiskRenderContainer extends React.Component {
    state = {
        mobileLayout: window.innerWidth <= mediaQueries.breakpointPhonePixels,
        tab: 'result',
        mappingElementsStatus: [],
        progressDone: false,
        setDefaultValueToAllFields: false
    };

    componentDidMount = () => window.addEventListener('resize', this.handleWindowResize)
    componentWillUnmount = () => window.removeEventListener('resize', this.handleWindowResize)

    handleWindowResize = () => this.setState({
        mobileLayout: window.innerWidth <= mediaQueries.breakpointPhonePixels
    })

    handleTabChange = (event, value) => {
        this.setState({ tab: value });
    };

    getFormControlBarComponents = () => {
        const { saveForm, formData, headRecord, moduleSecurity, progress, unsavedChanges, isLockable, newEvent, toggleRecordLock, deleteRecord, formSecurity, enableFormEdit, openReportPopup } = this.props;
        const { mappingElementsStatus } = this.state;
        let components = [];

        components.push(
            {
                type: 'button',
                label: 'Reports',
                onClick: openReportPopup,
                disabled: headRecord.isLocked || ((formSecurity.viewOnly && (newEvent || enableFormEdit)) ? false : formSecurity.viewOnly) || progress !== 100
            });
        if (moduleSecurity.allowDelete) {
            components.push({
                type: 'button',
                label: 'Delete',
                showConfirmation: true,
                confirmationTitle: 'Delete Record?',
                confirmationText: [
                    'This will permanently delete this record.',
                    'Ensure this is the correct record and patient before confirming.'
                ],
                onClick: deleteRecord,
                disabled: newEvent || headRecord.isLocked
            });
        }
        if (isLockable && ((!headRecord.isLocked) || (headRecord.isLocked))) {
            components.push({
                type: 'button',
                label: headRecord.isLocked ? 'Unlock' : 'Lock',
                showConfirmation: false,
                onClick: toggleRecordLock(!headRecord.isLocked),
                disabled: newEvent || progress !== 100 || !moduleSecurity.allowLock || !moduleSecurity.allowUnlock
            });
        }
        components.push({
            type: 'button',
            label: 'Save',
            showConfirmation: newEvent,
            confirmationTitle: 'Save?',
            confirmationText: [
                'Saving will create a new event record in the Apollo database.',
                'Do you want to proceed?'
            ],
            onClick: saveForm(mappingElementsStatus),
            confirmationStatus: true,
            disabled: headRecord.isLocked || ((formSecurity.viewOnly && (newEvent || enableFormEdit)) ? false : formSecurity.viewOnly) || progress !== 100 || (unsavedChanges ? false : true)
        });
        return components;
    };

    getFieldData = (itemSchema, formData, recordContext) => {
        if (formData && formData.tables && recordContext) {
            return {
                ...recordContext.getDataPoint(formData, itemSchema.tableName, itemSchema.fieldName)
            };
        } else {
            return {
                value: '',
                options: []
            };
        }
    };

    getFormMappingElements = () => {
        const { getMappingElements } = this.props;
        const { mappingElementsStatus } = this.state;
        getMappingElements(mappingElementsStatus);
    };

    getAllFormElements = (section) => {
        if (section.subSections && section.subSections.length > 0) {
            section.subSections.forEach((section) => {
                this.getAllFormElements(section);
            });
        }
        else {
            section.elements.forEach((element) => {
                this.addMappingElementStatus(element, "Unconfirmed", section.sectionName);
            });
        }
    }

    preInitializeCache = (section) => {
        if (section.subSections && section.subSections.length > 0) {
            section.subSections.forEach((section) => {
                this.preInitializeCache(section);
            });
        }
        else {
            section.elements.forEach((element) => {
                this.addMappingElementStatus(element, "Unconfirmed", section.sectionName);
            });
        }
    }

    initializeCache = (section, copilotFormData, globalContext) => {
        const { copilotFormCacheData } = this.props;
        let formstatusCacheData = Array.isArray(copilotFormCacheData) ? [...copilotFormCacheData] : [];
        if (section.subSections && section.subSections.length > 0) {
            section.subSections.forEach((section) => {
                this.initializeCache(section, copilotFormData, globalContext);
            });
        }
        else {
            section.elements.forEach((element) => {
                let dataPoint = this.getFieldData(element, copilotFormData, globalContext);
                if (formstatusCacheData.length > 0) {
                    //When cache data is comming from database.
                    this.updateMappingStatusAsPerCache(element, dataPoint, formstatusCacheData);

                } else {
                    //When cache data is not comming from database.
                    this.updateMappingStatusWithValue(element, dataPoint);
                }
            });
        }
    }

    areAllMappedMissing = (mappingElementsStatusArg) => {
        let newMappingElementsStatus = Array.isArray(mappingElementsStatusArg) ? [...mappingElementsStatusArg] : [];
        const result = newMappingElementsStatus.every(element => {
            if (element.mappedStatus === 'Mapped-Missing' || element.mappedStatus === 'Mapping-Error') {
                return true;
            }
        });
        return result;
    }

    addMappingElementStatus = (element, status, sectionName) => {
        const { mappingElementsStatus } = this.state;
        const { formMetaData } = this.props;
        let calculatedItemMetaData = formMetaData[element.metaDataKey];
        if (Array.isArray(calculatedItemMetaData?.emrMappings) && calculatedItemMetaData?.emrMappings.length > 0) {
            let arrOfElementsInMappingElementsStatus = mappingElementsStatus.filter((elementInList) => (elementInList.element === element.metaDataKey))
            if (arrOfElementsInMappingElementsStatus.length === 0) {
                let mappingElementStatus = {
                    section: sectionName,
                    element: element.metaDataKey,
                    elementDefaultValue: '',
                    conflictStatus: 'Nonconflicting data',
                    mappedStatus: "",
                    status,
                    remark: 'Pre-Initialization',
                    uniqueKey: element.uniqueKey,
                    apolloOption: '',
                    emrOptions: [],
                    mappingErrorDesc: ''
                };
                mappingElementsStatus.push(mappingElementStatus);
            }
            this.setState({ mappingElementsStatus });
        }
    }

    updateMappingStatus = (metaDataKey, conflicting, status, remark) => {
        const { updateEmrMappingStatus } = this.props;
        updateEmrMappingStatus(metaDataKey, conflicting, status, remark);
    }

    updateMappingStatusWithValue = (element, dataPoint) => {
        const { mappingElementsStatus } = this.state;
        const { formMetaData } = this.props;
        let elementValue = dataPoint.value;
        let options = dataPoint.options;
        let calculatedItemMetaData = formMetaData[element.metaDataKey];
        let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
        let valueOptions = Array.isArray(options) ? [...options] : [];
        let mappingElementStatus;
        if (mappingElementsStatus !== null && (Array.isArray(calculatedItemMetaData?.emrMappings) && calculatedItemMetaData?.emrMappings.length > 0)) {
            let arrOfElementsInMappingElementsStatus = newMappingElementsStatus.filter((e) => (e.element === element.metaDataKey))
            if (Array.isArray(arrOfElementsInMappingElementsStatus) && arrOfElementsInMappingElementsStatus.length > 0) {
                arrOfElementsInMappingElementsStatus.forEach(dupElement => {
                    let elementIndex = newMappingElementsStatus.map(elementStatus => elementStatus.uniqueKey).indexOf(dupElement.uniqueKey);

                    let tempEmrOptions = [];
                    if (valueOptions.length > 0) {
                        valueOptions.forEach(option => {
                            let tempEmrOption = {
                                value: option.value,
                                mapping: option?.mapping?.name,
                                sourceType: option?.mapping?.emrSource?.sourceType,
                                source: option?.mapping?.emrSource?.source,
                                emrOptionElements: []
                            };
                            let mappingElements = option?.mapping?.mappingElements;
                            if (mappingElements.length > 0) {
                                let valueOptionElements = [];
                                mappingElements.forEach(element => {
                                    let tempEmrOptionElement = {
                                        codeSystem: element.codeSystem,
                                        codeValue: element.codeValue,
                                        name: element.name
                                    };
                                    valueOptionElements.push(tempEmrOptionElement);
                                })
                                tempEmrOption.emrOptionElements = valueOptionElements;
                            }
                            tempEmrOptions.push(tempEmrOption);
                        });

                        let isElementValueNothing = false;
                        if ((elementValue === undefined || elementValue === null || elementValue === '')) {
                            isElementValueNothing = true;
                        }

                        let epicDataMatched = valueOptions.every(option => {
                            if (option === valueOptions[0]) {
                                return true;
                            }
                        });
                        let elementValueMatched = undefined;
                        if (!isElementValueNothing) {
                            elementValueMatched = valueOptions.find(option => option.value === elementValue);
                        }
                        if (elementValueMatched === undefined && calculatedItemMetaData.defaultInputType === 'CheckBox') {
                            switch (elementValue) {
                                case 1:
                                    elementValue = true;
                                    break;
                                case 0:
                                    elementValue = false;
                                    break;
                                default:
                                    elementValue = calculatedItemMetaData?.forceBooleanFalse ? false : null
                                    break;
                            }
                            elementValueMatched = valueOptions.find(option => option.value === elementValue);
                            isElementValueNothing = elementValue === null ? null : false;
                        }
                        let apolloSourceOption = elementValue + '|Apollo|Apollo Database';
                        mappingElementStatus = {
                            section: dupElement.section,
                            element: element.metaDataKey,
                            elementDefaultValue: elementValue,
                            conflictStatus: (elementValueMatched && epicDataMatched) || (isElementValueNothing && epicDataMatched) ? "Nonconflicting data" : "Conflicting data",
                            mappedStatus: "Mapped",
                            status: (elementValueMatched === undefined || isElementValueNothing || !epicDataMatched) ? "Unconfirmed" : "Confirmed",
                            remark: 'Initialization',
                            uniqueKey: dupElement.uniqueKey,
                            apolloOption: (elementValueMatched === undefined && !isElementValueNothing) ? apolloSourceOption : '',
                            emrOptions: tempEmrOptions,
                            mappingErrorDesc: ''
                        };
                    }
                    else {
                        mappingElementStatus = {
                            section: dupElement.section,
                            element: element.metaDataKey,
                            elementDefaultValue: elementValue,
                            conflictStatus: "",
                            mappedStatus: dataPoint.mappingExpressionError ? "Mapping-Error" : "Mapped-Missing",
                            status: "",
                            remark: 'Initialization',
                            uniqueKey: dupElement.uniqueKey,
                            apolloOption: '',
                            emrOptions: [],
                            mappingErrorDesc: dataPoint.mappingExpressionErrorDesc
                        };
                    }
                    mappingElementsStatus.splice(elementIndex, 1, mappingElementStatus);
                    this.setState({ mappingElementsStatus });
                });
            }
        }
    }

    updateMappingStatusAsPerCache = (element, dataPoint, formstatusCacheData) => {
        const { mappingElementsStatus } = this.state;
        const { formMetaData } = this.props;
        let elementValue = dataPoint.value;
        let options = dataPoint.options;
        let calculatedItemMetaData = formMetaData[element.metaDataKey];
        let newMappingElementsStatus = Array.isArray(mappingElementsStatus) ? [...mappingElementsStatus] : [];
        let valueOptions = Array.isArray(options) ? [...options] : [];
        let mappingElementStatus;
        if (mappingElementsStatus !== null && (Array.isArray(calculatedItemMetaData?.emrMappings) && calculatedItemMetaData?.emrMappings.length > 0)) {
            let arrOfElementsInMappingElementsStatus = newMappingElementsStatus.filter((e) => (e.element === element.metaDataKey))
            if (Array.isArray(arrOfElementsInMappingElementsStatus) && arrOfElementsInMappingElementsStatus.length > 0) {
                arrOfElementsInMappingElementsStatus.forEach(dupElement => {
                    let elementIndex = newMappingElementsStatus.map(elementStatus => elementStatus.uniqueKey).indexOf(dupElement.uniqueKey);
                    let cachedElement = formstatusCacheData.find(cacheElementData => cacheElementData.uniqueKey === dupElement.uniqueKey);
                    if (cachedElement !== undefined) {
                        let isMappedMissingOrMappedError = false;
                        if ((cachedElement.mappedStatus === 'Mapped-Missing' || cachedElement.mappedStatus === 'Mapping-Error') && (valueOptions.length === 0)) {
                            isMappedMissingOrMappedError = true;
                        }
                        if (!isMappedMissingOrMappedError) {
                            //Create array of mapping values and elements from FHIR data which needs to compare with mapping data from cache.
                            let tempValueOptions = [];
                            if (valueOptions.length > 0) {
                                valueOptions.forEach(option => {
                                    let tempValueOption = {
                                        value: option.value,
                                        mapping: option?.mapping?.name,
                                        sourceType: option?.mapping?.emrSource?.sourceType,
                                        source: option?.mapping?.emrSource?.source,
                                        emrOptionElements: []
                                    };
                                    let mappingElements = option?.mapping?.mappingElements;
                                    if (mappingElements.length > 0) {
                                        let valueOptionElements = [];
                                        mappingElements.forEach(element => {
                                            let tempEmrOptionElement = {
                                                codeSystem: element.codeSystem,
                                                codeValue: element.codeValue,
                                                name: element.name
                                            };
                                            valueOptionElements.push(tempEmrOptionElement);
                                        })
                                        tempValueOption.emrOptionElements = valueOptionElements;
                                    }
                                    tempValueOptions.push(tempValueOption);
                                });
                            }
                            //Compare here recent FHIR data with mapping data from cache. if there is difference between two, then mark data as "Unconfirmed"
                            let hasEqualOptionsInCacheAndEpic = this.equalsCheck(cachedElement.emrOptions, tempValueOptions);

                            let cachedEmrOptions = Array.isArray(cachedElement.emrOptions) ? [...cachedElement.emrOptions] : [];
                            let isElementValueNothing = false;
                            if ((elementValue === undefined || elementValue === null || elementValue === '')) {
                                isElementValueNothing = true;
                            }

                            let epicDataMatched;
                            let elementValueMatched;
                            //If comparision is equal, then use data from cache, else use newly arrived FHIR data.
                            if (hasEqualOptionsInCacheAndEpic) {
                                epicDataMatched = cachedEmrOptions.every(option => {
                                    if (option === cachedEmrOptions[0]) {
                                        return true;
                                    }
                                });
                                elementValueMatched = undefined;
                                if (!isElementValueNothing) {
                                    elementValueMatched = cachedEmrOptions.find(option => option.value === elementValue);
                                }
                            } else {
                                epicDataMatched = tempValueOptions.every(option => {
                                    if (option === tempValueOptions[0]) {
                                        return true;
                                    }
                                });
                                elementValueMatched = undefined;
                                if (!isElementValueNothing) {
                                    elementValueMatched = tempValueOptions.find(option => option.value === elementValue);
                                }
                            }

                            if (elementValueMatched === undefined && calculatedItemMetaData.defaultInputType === 'CheckBox') {
                                switch (elementValue) {
                                    case 1:
                                        elementValue = true;
                                        break;
                                    case 0:
                                        elementValue = false;
                                        break;
                                    default:
                                        elementValue = calculatedItemMetaData?.forceBooleanFalse ? false : null
                                        break;
                                }
                                if (hasEqualOptionsInCacheAndEpic) {
                                    elementValueMatched = cachedEmrOptions.find(option => option.value === elementValue);
                                } else {
                                    elementValueMatched = tempValueOptions.find(option => option.value === elementValue);
                                }

                                isElementValueNothing = elementValue === null ? null : false;
                            }
                            let apolloSourceOption = elementValue + '|Apollo|Apollo Database';
                            mappingElementStatus = {
                                section: dupElement.section,
                                element: element.metaDataKey,
                                elementDefaultValue: elementValue,
                                conflictStatus: (elementValueMatched && epicDataMatched) || (isElementValueNothing && epicDataMatched) ? "Nonconflicting data" : "Conflicting data",
                                mappedStatus: "Mapped",
                                status: hasEqualOptionsInCacheAndEpic ? cachedElement !== undefined ? cachedElement.status : (elementValueMatched === undefined || isElementValueNothing || !epicDataMatched) ? "Unconfirmed" : "Confirmed" : "Unconfirmed",
                                remark: 'Initialization',
                                uniqueKey: dupElement.uniqueKey,
                                apolloOption: (elementValueMatched === undefined && !isElementValueNothing) ? apolloSourceOption : '',
                                emrOptions: hasEqualOptionsInCacheAndEpic && cachedElement !== undefined ? cachedEmrOptions : tempValueOptions,
                                mappingErrorDesc: ''
                            };
                            //If mapping error has been fixed or element got mappings, then set state of Confirm/UnConfirm as per element match with Epic data.
                            //Also set emrOptions with new Epic data which comes after fixing the mapping error or after getting new mappings.
                            if ((cachedElement.mappedStatus === 'Mapped-Missing' || cachedElement.mappedStatus === 'Mapping-Error') && (cachedElement.status === "")) {
                                mappingElementStatus.status = (isElementValueNothing || elementValueMatched === undefined || !epicDataMatched) ? "Unconfirmed" : "Confirmed";
                                mappingElementStatus.emrOptions = tempValueOptions;
                            }
                        }
                        else {
                            mappingElementStatus = {
                                section: dupElement.section,
                                element: element.metaDataKey,
                                elementDefaultValue: elementValue,
                                conflictStatus: "",
                                mappedStatus: cachedElement.mappedStatus,
                                status: "",
                                remark: 'Initialization',
                                uniqueKey: dupElement.uniqueKey,
                                apolloOption: '',
                                emrOptions: [],
                                mappingErrorDesc: dataPoint.mappingExpressionErrorDesc
                            };
                        }
                        mappingElementsStatus.splice(elementIndex, 1, mappingElementStatus);
                        this.setState({ mappingElementsStatus });
                    }
                });
            }
        }
    }

    equalsCheck = (a, b) => {
        return (
            (Array.isArray(a) && Array.isArray(b)) && (a.length === b.length) &&
            JSON.stringify(a) === JSON.stringify(b)
        );
    }

    render() {
        const {
            headRecord,
            loadingResult,
            formSchema,
            formMetaData,
            sectionList,
            formData,
            formResult,
            progress,
            unsavedChanges,
            onChange,
            onBlur,
            executeCommandButton,
            classes,
            illegalFieldsErrorMessageOpen,
            illegalFields,
            handleIllegalFieldsErrorMessageClose,
            handleSavedSnackbarClose,
            savedSnackbarOpen,
            lockStateTarget,
            settings,
            triggerScroll,
            isLocked,
            formSecurity,
            newEvent,
            enableFormEdit,
            onHideFormViewOnlyBar,
            moduleSecurity,
            openStaffDialog,
            commandButtonSchema,
            closeStaffLookup,
            openReportPopup,
            openPopupDialog,
            closePopupForm,
            popupSectionSchema,
            copilotFormCacheData,
            patientInfo,
            mappingElements,
            onFormSectionChange
        } = this.props;

        const {
            mobileLayout,
            tab,
            mappingElementsStatus,
            progressDone,
            setDefaultValueToAllFields
        } = this.state;

        let formControlBarComponents = this.getFormControlBarComponents();
        let recordContext = new ContextManager(formData?.globalContext);

        //InitializeCache - with element default values.
        if (progress === 100) {
            if (!formSecurity.viewOnly || enableFormEdit) {
                if (!setDefaultValueToAllFields) {
                    formSchema.sections.forEach((section) => {
                        this.getAllFormElements(section);
                    });
                    this.getFormMappingElements();
                    this.setState({
                        setDefaultValueToAllFields: true
                    });
                }
            }
        }
        else {
            if (setDefaultValueToAllFields) {
                this.setState({
                    setDefaultValueToAllFields: false
                });
            }
        }

        let formLoadingLable = '"' + formSchema.header.formDisplayName + '" form is loading. (' + progress + '%)'
        return (
            <React.Fragment>
                {/* <Hidden xsUp={!mobileLayout}>
                    <FormControlBar components={formControlBarComponents} />
                </Hidden> */}
                <GridContainer justify='center' spacing={1}>

                    <Hidden xsUp={mobileLayout && tab === 'result'}>
                        <GridItem xs={8}>
                            <div className={classes.patientInfo} style={{ textAlign: "left" }}>
                                {'\n'} MRN: {patientInfo.patientId} &nbsp;&nbsp;&nbsp;&nbsp; Name: {patientInfo.lastName},&nbsp; {patientInfo.firstName}&nbsp;&nbsp;&nbsp;&nbsp; DOB: {moment(patientInfo.dateOfBirth).format('MM/DD/YYYY')}
                            </div>
                        </GridItem>
                        <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Hidden xsUp={mobileLayout}>
                                <FormControlBar components={formControlBarComponents} />
                            </Hidden>
                        </GridItem>
                        <GridItem xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Hidden xsUp={mobileLayout}>
                                {
                                    (formSecurity.viewOnly && !newEvent && !enableFormEdit && progress === 100) ?
                                        (
                                            <div className={classes.cardWidth}>
                                                <FormViewOnlyBar
                                                    formDisplayName={formSchema.header.formDisplayName}
                                                    onEnableEditing={onHideFormViewOnlyBar}
                                                    moduleSecurity={moduleSecurity}
                                                />
                                            </div>
                                        )
                                        :
                                        null
                                }
                            </Hidden>
                        </GridItem>
                        <GridItem className={classes.formGrid} >
                            {progress !== 100 || !formData ?
                                <>
                                    <div className={classes.progressBar}>
                                        <LinearProgress variant="determinate" value={progress} />
                                    </div>
                                    <div>
                                        <Card className={classes.paper}>
                                            <CardBody className={classes.cardBody}>
                                                <CircularProgress className={classes.title} size={100} thickness={7} color='secondary' />
                                                <br></br>
                                                <Typography align='center' variant='h7'>
                                                    {formLoadingLable}
                                                </Typography>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </>
                                : null
                            }
                            {progress === 100 ?
                                <DynamicForm
                                    formSchema={formSchema}
                                    formMetaData={formMetaData}
                                    sectionList={sectionList}
                                    formData={formData}
                                    executeCommandButton={executeCommandButton}
                                    recordContext={recordContext}
                                    tabView={true}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    settings={settings}
                                    triggerScroll={triggerScroll}
                                    isLocked={isLocked}
                                    formSecurity={formSecurity}
                                    newEvent={newEvent}
                                    enableFormEdit={enableFormEdit}
                                    mappingElementsStatus={progress === 100 ? mappingElements : []}
                                    onUpdateMappingStatus={this.updateMappingStatus}
                                    formLoadProgress={progress}
                                    onFormSectionChange={this.props.onFormSectionChange}
                                />
                                : null
                            }
                        </GridItem>
                    </Hidden>
                    <Hidden xsUp={mobileLayout && tab === 'form'}>
                        <GridItem className={classes.sidebarGrid}>
                            <ResultContainer
                                classes={{ sidebar: enableFormEdit ? classes.sidebarEdit : classes.sidebar }}
                                riskResult={formResult}
                                resultCalculating={loadingResult}
                            />
                        </GridItem>
                    </Hidden>
                </GridContainer>
                <Hidden xsUp={!mobileLayout}>
                    <Tabs className={classes.mobileTabs} value={tab} onChange={this.handleTabChange} indicatorColor='primary' centered>
                        <Tab className={classes.mobileTab} value='result' label="Results" />
                        <Tab className={classes.mobileTab} value='form' label="Form" />
                    </Tabs>
                </Hidden>
                <FormsIllegalDataDialog
                    onClose={handleIllegalFieldsErrorMessageClose}
                    open={illegalFieldsErrorMessageOpen}
                    illegalFields={illegalFields}
                />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={savedSnackbarOpen}
                    onClose={handleSavedSnackbarClose}
                    message="Saved!"
                    autoHideDuration={3000}
                />
                <Backdrop className={classes.backendActionBackdrop} open={lockStateTarget !== null}>
                    <CircularProgress size={60} thickness={7} color='secondary' />
                </Backdrop>
                <Dialog open={openStaffDialog} onClose={closeStaffLookup} maxWidth="xl">
                    <Close
                        className={classes.closeIcon}
                        onClick={closeStaffLookup}
                    />
                    <DialogContent>
                        <StaffContainer
                            recordContext={recordContext}
                            onBlur={onBlur}
                            itemSchema={commandButtonSchema}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog open={openPopupDialog} onClose={closePopupForm} fullWidth={true} maxWidth="xl">
                    <Close
                        className={classes.closeIcon}
                        onClick={closePopupForm}
                    />
                    <DialogContent>
                        <DynamicFormSection
                            sectionSchema={popupSectionSchema}
                            formMetaData={formMetaData}
                            formData={formData}
                            recordContext={recordContext}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            tabView={'true'}
                            isLocked={headRecord.isLocked}
                        />
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(StsSurgicalRiskRenderContainer);