import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardHeader from '.../assets/components/Card/CardHeader';
import DynamicFormItem from './DynamicFormItem';
import DynamicFormTabsSection from "./DynamicFormTabsSection";

const styles = theme => ({
    paper: {
        margin: `${theme.spacing(1)}px`,
        width: '97%',
        height: `calc(100vh - 100px)`,
        overflowY: 'auto',
        MsOverflowStyle: 'scrollbar'
    },
    cardDivider: {
        margin: `${theme.spacing(1)}px 0px`
    },
    dividerLineTitle: {
        display: 'flex',
        alignItems: 'center',
        margin: `${theme.spacing(1)}px 0px`
    },
    dividerTitle: {
        flex: '0 0 auto',
        marginRight: `${theme.spacing(2)}px`
    },
    dividerLine: {
        flex: '1 0 auto'
    },
    itemContainer: {
        alignItems: 'center',
        display: 'flex'
    },
    cardBody: {
        padding: '0px'
    }
});

const ResponsiveReactGridLayout = WidthProvider(Responsive);
let issectionLoading = true;

class DynamicFormSection extends React.Component {
    ensureArray = (item) => {
        let retval = item;
        if (!Array.isArray(item)) {
            retval = [item];
        }
        return retval;
    }

    renderFormItem = (itemSchema, index, tabView) => {
        const {
            formMetaData,
            formData,
            recordContext,
            settings,
            classes,
            onBlur,
            onChange,
            onAddRecord,
            onDeleteRecords,
            setRecordCurrency,
            executeCommandButton,
            highlightedFieldKeyArray,
            highlightedRecordKey,
            clearHighlightedField,
            triggerScroll,
            isLocked, mappingElementsStatus, onUpdateMappingStatus, formLoadProgress,viewName
        } = this.props;
        return (
            <div key={itemSchema.uniqueKey} className={`nonDraggableSection ${classes.itemContainer}`}>
                <DynamicFormItem
                    itemSchema={itemSchema}
                    executeCommandButton={executeCommandButton}
                    formMetaData={formMetaData}
                    formData={formData}
                    recordContext={recordContext}
                    settings={settings}
                    onChange={onChange}
                    onBlur={onBlur}
                    onAddRecord={onAddRecord}
                    onDeleteRecords={onDeleteRecords}
                    setRecordCurrency={setRecordCurrency}
                    autoFocus={tabView ? index === 0 : itemSchema.tabIndex === 1}
                    highlightedFieldKeyArray={highlightedFieldKeyArray}
                    highlightedRecordKey={highlightedRecordKey}
                    clearHighlightedField={clearHighlightedField}
                    triggerScroll={triggerScroll}
                    isLocked={isLocked}
                    mappingElementsStatus={mappingElementsStatus}
                    onUpdateMappingStatus={onUpdateMappingStatus}
                    formLoadProgress={formLoadProgress}
                    viewName={viewName}
                />
            </div>
        );
    }

    generateLayouts = () => {
        const { sectionSchema } = this.props;
        return this.generateLayout(sectionSchema);
    };

    isFormSectionLoading = () => {
        const { currentSectionName, formData, sectionList} = this.props;

        if(!sectionList)
            return false;
        
        let sectionListItem = sectionList[currentSectionName];
        let loadSection = false;
        issectionLoading = true;
        if(sectionListItem != null){
            if(sectionListItem.parentName == null)
                {
                    if(formData != null && Object.keys(formData).length > 0)
                        {
                         if(formData.loadedSections!== undefined){
                                formData.loadedSections.forEach((section) => {
                                       if (section.name === currentSectionName) {
                                            loadSection = section.loaded;
                                     }
                                });
                            }
                        }
                }
            else{
                if(formData != null && Object.keys(formData).length > 0)
                    {
                     if(formData.loadedSections!== undefined){
                            formData.loadedSections.forEach((section) => {
                                   if (section.name === sectionListItem.parentName) {
                                        loadSection = section.loaded;
                                 }
                            });
                        }
                    }
            }
        }

        if (loadSection) {
           issectionLoading = false;
        }
        
         return issectionLoading;
    };

    generateLayout = (sectionSchema) => {
        let layouts = {
            sm: [],
            lg: []
        };

        sectionSchema.elements.forEach((item, index) => {
            Object.getOwnPropertyNames(item.fieldLayoutMatrix).forEach(breakpoint => {
                if (typeof layouts[breakpoint] === 'undefined') {
                    layouts[breakpoint] = [];
                }
                if (layouts !== undefined) {
                    layouts[breakpoint].push({
                        i: item.uniqueKey,
                        isResizable: false,
                        h: item.fieldLayoutMatrix[breakpoint].h,
                        w: item.fieldLayoutMatrix[breakpoint].w,
                        x: item.fieldLayoutMatrix[breakpoint].x,
                        y: item.fieldLayoutMatrix[breakpoint].y
                    });
                }
            });
        });

        return layouts;
    };

    renderSection(section) {
        const { tabView, getSubSectionIndex, setCurrentSectionName, currentSectionName, sectionList, classes } = this.props;

        let tabs;
        if (section.subSections && section.subSections.length > 0) {
            tabs =
                <DynamicFormTabsSection
                    section={section}
                    renderSection={(s) => this.renderSection(s)}
                    getSubSectionIndex={getSubSectionIndex}
                    setCurrentSectionName={setCurrentSectionName}
                    currentSectionName={currentSectionName}
                    sectionList={sectionList}
                />;
        }
        return (
            <CardBody key={section.name} className={classes.cardBody}>
                <ResponsiveReactGridLayout
                    breakpoints={{
                        sm: 0,
                        lg: 600
                    }}
                    cols={{
                        sm: 8,
                        lg: 32
                    }}
                    layouts={this.generateLayout(section)}
                    margin={[20, 30]}
                    rowHeight={35}
                    isDraggable={false}
                    isResizable={false}
                    draggableCancel='.nonDraggableSection'
                    compactType={null}
                >
                    {section.elements.sort((a, b) => (a.tabIndex > b.tabIndex) ? 1 : -1).map((element, index) => this.renderFormItem(element, index, tabView))}
                </ResponsiveReactGridLayout>
                {tabs}
            </CardBody>
        );
    }
    render() {
        const { sectionSchema, classes } = this.props;
        return (
            <Card className={classes.paper}>
                { this.isFormSectionLoading() ?
                <>
                    <div>
                        <Card className={classes.paper}  >
                            <CardBody className={classes.cardBody} >
                                <CircularProgress className={classes.title} size={100} thickness={7} color='secondary' />
                            </CardBody>
                        </Card>
                    </div>
                </>
                : <>
            
                {
                    sectionSchema.sectionDisplayName ?
                        <CardHeader>
                            <div className={classes.dividerLineTitle}>
                                <Typography align='left' variant='body2' className={classes.dividerTitle}>
                                    {sectionSchema.sectionDisplayName.toUpperCase()}
                                </Typography>
                                <Divider className={`${classes.cardDivider} ${classes.dividerLine}`} />
                            </div>
                        </CardHeader>
                        :
                        null
                }
                {this.renderSection(this.props.sectionSchema)}
                </>
             }   
            </Card>
        );
    }
}

export default withStyles(styles)(DynamicFormSection);