import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

import MultiColumnCombo from '.../components/formComponents/formElements/MultiColumnCombo';
import LxDynamicFormField from '.../components/formComponents/LxDynamicFormField';

const styles = theme => ({
    dividerLineTitle: {
        display: 'flex',
        margin: `${theme.spacing(1)}px 0px`,
        height: '100%',
        width: '100%',
        overflow: 'auto',
        //this MsOverflowStyle is required for a known bug in IE that causes scrollbars to fail to appear under some circumstances. Has no effect on chrome.
        MsOverflowStyle: 'scrollbar',
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#bbbbbb',
        flexDirection: 'column'
    },
    dividerTitle: {
        flex: '0 0 auto',
        marginRight: `${theme.spacing(2)}px`
    },
    dividerLine: {
        flex: '1 0 auto'
    },
    table: {
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#bbbbbb'
    },
    tableBody: {
        height: '100%',
        width: '100%',
        overflow: 'auto'
    },
    tableCell: {
        border: '1px',
        padding: '3px 6px 3px 6px!important',
        borderStyle: 'solid',
        borderColor: '#bbbbbb'
    },
    highlightedCell: {
        backgroundColor: 'rgba(37, 165, 137, 0.3)'
    },
    headerCell: {
        border: '1px',
        padding: '3px 6px 3px 6px!important',
        borderStyle: 'solid',
        borderColor: '#bbbbbb',
        backgroundColor: '#dadada',
        textAlign: 'center'
    },
    deleteHeaderCell: {
        border: '1px',
        padding: '3px 6px 3px 6px!important',
        borderStyle: 'solid',
        borderColor: '#bbbbbb',
        backgroundColor: '#dadada',
        textAlign: 'center',
        width: '30px',
        minWidth: '22px'
    },
    deleteCell: {
        border: '1px',
        padding: '3px 6px 3px 6px!important',
        borderStyle: 'solid',
        borderColor: '#bbbbbb',
        textAlign: 'center',
        width: '52px'
    },
    deleteButton: {
        width: '30px',
        minWidth: '22px'
    },
    addButton: {
        height: '36px',
        margin: '0px',
        position: 'absolute',
        top: '6px',
        right: '25px'
    },
    gridHeader: {
        margin: '5px',
        minHeight: '25px',
        display: 'flex',
        flexWrap: 'wrap'
    },
    scrollableBody: {
        marginBottom: '45px'
    },
    tableWrapper: {
        overflow: 'auto'
    },
    toggleButtonPlaceholder: {
        minWidth: '130px',
        minHeight: '30px'
    },
    typographyFullWidth: {
        flexGrow: '1'
    },
    selectedTableRow: {
        background: '#f1f1f1'
    },
    headerSection: {
        minHeight: '35px'
    }
});

//This object is a PureComponent, so a state change should not cause everything to rerender.
class GridView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.scrollableDivRef = React.createRef(null);
        this.state = {
            setCellFocusCollection: {}
        };
    }

    handleCellBlur = (dataPoints) => {
        const { onBlur, clearHighlightedField } = this.props;
        if (typeof onBlur === 'function') {
            onBlur(dataPoints);
            if (typeof clearHighlightedField === 'function') {
                clearHighlightedField();
            }
        }
    }

    handleDeleteRow = (recordKey) => () => {
        const { itemSchema, onDeleteRecords } = this.props;
        let recordKeys = [];
        recordKeys.push(recordKey);
        onDeleteRecords(itemSchema.contextTableName, recordKeys);
    }

    handleAddRecord = () => {
        const { itemSchema, onAddRecord, recordContext, formData } = this.props;

        if (formData?.tables) {
            let contextTable = formData.tables[itemSchema.contextTableName];
            if (contextTable !== undefined) {
                let parentTableNode = recordContext.recordContext[contextTable.parentTableName];
                onAddRecord(itemSchema.contextTableName, parentTableNode.key);
            }
        }
    }

    handleSetRecordCurrency = (recordKeyValue) => () => {
        const { itemSchema, setRecordCurrency, clearHighlightedField } = this.props;

        if (typeof setRecordCurrency === 'function') {
            setRecordCurrency(itemSchema.contextTableName, recordKeyValue);
            if (typeof clearHighlightedField === 'function') {
                clearHighlightedField();
            }
        }
    }

    handleScrollTriggered = (cellRef) => {
        const { triggerScroll } = this.props;
        if (cellRef) {
            cellRef.scrollIntoView();
            triggerScroll(cellRef);
            if (this.scrollableDivRef.current && cellRef) {
                let containerRect = this.scrollableDivRef.current.getBoundingClientRect();
                let halfContainerHeight = containerRect.height / 2;
                let containerHalfwayHeight = containerRect.top + halfContainerHeight;
                let cellRect = cellRef.getBoundingClientRect();
                //If the element that is being scrolled into center is below halfway down the table, don't shift the scrollbar up a half page. This is because it must be towards the bottom of the content.
                if (cellRect.top <= containerHalfwayHeight) {
                    this.scrollableDivRef.current.scrollTop = this.scrollableDivRef.current.scrollTop - halfContainerHeight;
                }
            }
        }
    }

    onCellKeyDown = (record, column, columnMetaData, recordIndex, allBaseRecords) => (event) => {
        const { setCellFocusCollection } = this.state;
        //Up & Down Arrow Keys
        if (event.keyCode === 38 || event.keyCode === 40) {
            event.preventDefault();
            //Up Arrow Keykeycode = 38
            if (event.keyCode === 38 && recordIndex > 0) {
                //Navigate Up 1 row
                let targetRecord = this.recursivelyFindPrevRecord(recordIndex, column, columnMetaData, allBaseRecords);
                if (targetRecord) {
                    let targetCellKey = `${targetRecord.keyValue}:${column.fieldName}`;
                    let targetCellFocusFunction = setCellFocusCollection[targetCellKey];
                    if (targetCellFocusFunction) {
                        targetCellFocusFunction();
                    }
                }
            }
            let recordMax = Array.isArray(allBaseRecords) ? allBaseRecords.length - 1 : 0;
            //Down Arrow Key keycode = 40
            if (event.keyCode === 40 && recordIndex < recordMax) {
                //Navigate Down 1 row
                let targetRecord = this.recursivelyFindNextRecord(recordIndex, column, columnMetaData, allBaseRecords, recordMax);
                if (targetRecord) {
                    let targetCellKey = `${targetRecord.keyValue}:${column.fieldName}`;
                    let targetCellFocusFunction = setCellFocusCollection[targetCellKey];
                    if (targetCellFocusFunction) {
                        targetCellFocusFunction();
                    }
                }
            }
        }
    }

    recursivelyFindNextRecord = (currentRecordIndex, column, columnMetaData, allBaseRecords, recordMax) => {
        if (currentRecordIndex < recordMax) {
            let nextRecordIndex = currentRecordIndex + 1;
            let targetRecord = allBaseRecords[nextRecordIndex];
            let targetDataPoint = targetRecord.fields[column.fieldName];
            let targetDisabled = column.readOnly || columnMetaData.forcedReadOnly || !targetDataPoint.isValidChild;
            if (targetDataPoint && !targetDisabled) {
                return targetRecord;
            } else {
                return this.recursivelyFindNextRecord(nextRecordIndex, column, columnMetaData, allBaseRecords, recordMax);
            }
        }
    }

    recursivelyFindPrevRecord = (currentRecordIndex, column, columnMetaData, allBaseRecords) => {
        if (currentRecordIndex > 0) {
            let prevRecordIndex = currentRecordIndex - 1;
            let targetRecord = allBaseRecords[prevRecordIndex];
            let targetDataPoint = targetRecord.fields[column.fieldName];
            let targetDisabled = column.readOnly || columnMetaData.forcedReadOnly || !targetDataPoint.isValidChild;
            if (targetDataPoint && !targetDisabled) {
                return targetRecord;
            } else {
                return this.recursivelyFindPrevRecord(prevRecordIndex, column, columnMetaData, allBaseRecords);
            }
        }
    }

    registerFocusCellFunction = (recordKeyValue, column) => (inputRef) => {
        const { setCellFocusCollection } = this.state;
        setCellFocusCollection[`${recordKeyValue}:${column.fieldName}`] = () => {
            if (inputRef) {
                inputRef.focus();
            }
        };
        //This object is a PureComponent, so a state change should not cause everything to rerender.
        this.setState({ setCellFocusCollection });
    }

    renderTableFieldCell = (record, column, recordIndex, allBaseRecords) => {
        const { classes, formMetaData, itemSchema, settings } = this.props;
        //TODO getting related records poses a few problems that havent been solved yet, so I'm disabling it for now.
        //let targetRecord = recordContext.getRelatedRecord(column.tableName, record, formData);
        let columnMetaData = formMetaData[`${itemSchema.contextTableName}.${column.fieldName}`];
        let targetRecord = record;
        let highlighted = this.isHighlighted(itemSchema.contextTableName, column.fieldName, record.keyValue);
        let className = highlighted ? `${classes.tableCell} ${classes.highlightedCell}` : classes.tableCell;
        //All the individual grid cells need to take the tabIndex from the overall grid so that tabing into and out of the grid functions properly.
        column.tabIndex = itemSchema.tabIndex;

        if (targetRecord && columnMetaData) {
            let field = targetRecord.fields[column.fieldName];
            if (field) {
                return (
                    <TableCell
                        key={`${record.keyValue}:${itemSchema.contextTableName}.${column.fieldName}`}
                        className={className}
                        ref={ref => {
                            if (highlighted && ref) {
                                this.handleScrollTriggered(ref);
                            }
                        }}
                        onKeyDown={this.onCellKeyDown(record, column, columnMetaData, recordIndex, allBaseRecords)}
                    >
                        <LxDynamicFormField
                            itemSchema={column}
                            itemMetaData={columnMetaData}
                            dataPoint={field}
                            settings={settings}
                            onBlur={this.handleCellBlur}
                            inputRef={this.registerFocusCellFunction(record.keyValue, column)}
                            inputOnly
                            forceSelectionSet
                        />
                    </TableCell>
                );
            }
        }
        else if (!columnMetaData) {
            return (
                <TableCell key={`${record.keyValue}:${column.tableName}.${column.fieldName}`} className={classes.tableCell}>
                    InvalidField
                </TableCell>
            );
        }
        return (
            //Render disabled cell
            <TableCell key={`${record.keyValue}:${column.tableName}.${column.fieldName}`} className={className}>
                <LxDynamicFormField
                    itemSchema={column}
                    itemMetaData={columnMetaData}
                    forceDisabled
                    onBlur={this.handleCellBlur}
                    inputOnly
                    forceSelectionSet
                />
            </TableCell>
        );
    }

    renderMultiColumnCell = (record, column, recordIndex, allBaseRecords) => {
        const { classes, formMetaData, itemSchema, settings, onBlur, onChange, recordContext, formData, clearHighlightedField } = this.props;

        //TODO getting related records poses a few problems that havent been solved yet, so I'm disabling it for now.
        //let targetRecord = recordContext.getRelatedRecord(column.tableName, record, formData);
        let columnMetaData = formMetaData[`${itemSchema.contextTableName}.${column.fieldName}`];
        let targetRecord = record;
        let className = this.isHighlighted(itemSchema.contextTableName, column.fieldName, record.keyValue) ? `${classes.tableCell} ${classes.highlightedCell}` : classes.tableCell;
        //All the individual grid cells need to take the tabIndex from the overall grid so that tabing into and out of the grid functions properly.
        column.tabIndex = itemSchema.tabIndex;

        if (targetRecord && columnMetaData) {
            let field = targetRecord.fields[column.fieldName];
            if (field) {
                return (
                    <TableCell key={`${record.keyValue}:${itemSchema.contextTableName}.${column.fieldName}`} className={className}>
                        <MultiColumnCombo
                            itemSchema={column}
                            formMetaData={formMetaData}
                            dataPoint={field}
                            formData={formData}
                            recordContext={recordContext}
                            settings={settings}
                            onChange={onChange}
                            onBlur={onBlur}
                            clearHighlightedField={clearHighlightedField}
                            hideLabel
                        />
                    </TableCell>
                );
            }
        }
        else if (!columnMetaData) {
            return (
                <TableCell key={`${record.keyValue}:${column.tableName}.${column.fieldName}`} className={classes.tableCell}>
                    InvalidField
                </TableCell>
            );
        }
        return (
            //Render disabled cell
            <TableCell key={`${record.keyValue}:${column.tableName}.${column.fieldName}`} className={className}>
                <LxDynamicFormField
                    itemSchema={column}
                    itemMetaData={columnMetaData}
                    forceDisabled
                    onBlur={this.handleCellBlur}
                    inputOnly
                    forceSelectionSet
                />
            </TableCell>
        );
    }

    renderGridCell = (record, column, recordIndex, allBaseRecords) => {
        switch (column.$lumedxType) {
            case 'TableField':
                return this.renderTableFieldCell(record, column, recordIndex, allBaseRecords);
            case 'MultiColumn':
                return this.renderMultiColumnCell(record, column, recordIndex, allBaseRecords);
            //Do we even want a default here?
            default:
                return this.renderTableFieldCell(record, column, recordIndex, allBaseRecords);
        }

    }

    renderRecord = (record, recordIndex, allBaseRecords) => {
        const { classes, itemSchema, recordContext, disabled } = this.props;

        let contextTableNode = recordContext.recordContext[itemSchema.contextTableName];
        let selected = contextTableNode.key === record.keyValue;
        return (
            <TableRow key={record.keyValue} className={selected ? classes.selectedTableRow : null} onClick={this.handleSetRecordCurrency(record.keyValue)}>
                {itemSchema.columns.map(column => this.renderGridCell(record, column, recordIndex, allBaseRecords))}
                <TableCell className={classes.deleteCell}>
                    <Button className={classes.deleteButton} onClick={this.handleDeleteRow(record.keyValue)} disabled={disabled}>
                        <DeleteIcon />
                    </Button>
                </TableCell>
            </TableRow>
        );
    }

    renderTableBody = () => {
        const { classes, itemSchema, formData, recordContext } = this.props;

        let allBaseRecords = {};
        //Ensure Required Data Exists: 
        if (recordContext?.recordContext && itemSchema.contextTableName && formData) {
            allBaseRecords = recordContext.getChildRecords(formData, itemSchema.contextTableName);
            if (allBaseRecords.length > 0) {
                return (
                    <TableBody className={classes.scrollableBody}>
                        {allBaseRecords?.map((record, index) => this.renderRecord(record, index, allBaseRecords))}
                    </TableBody>
                );
            } else {
                return null;
            }
        }
    }

    renderAddRowButton = () => {
        const { classes, recordContext, formData, itemSchema, disabled } = this.props;        
        if (formData?.tables) {
            let contextTable = formData.tables[itemSchema.contextTableName];
            let disabledGrid = contextTable?.isDisabled;

            if (recordContext?.recordContext && !disabledGrid) {
                let isButtonDisabled = (contextTable.isOneToOne === true) ? true : disabled;
                return (
                    <Button className={classes.addButton} onClick={this.handleAddRecord} disabled={isButtonDisabled}>
                        Add New Row
                        <AddIcon />
                    </Button>
                );
            }
            else {
                return null;
            }
        }
    }

    isHighlighted = (tableName, fieldName, recordKey) => {
        const { itemSchema, highlightedFieldKeyArray, highlightedRecordKey } = this.props;
        let retval = false;

        if (Array.isArray(highlightedFieldKeyArray) && highlightedFieldKeyArray.length > 0) {
            let fieldKeyArrayCopy = [...highlightedFieldKeyArray];
            fieldKeyArrayCopy.shift();
            let targetKey = fieldKeyArrayCopy.shift();
            let columnKey = `${itemSchema.contextTableName}.${fieldName}`;
            if (columnKey === targetKey && recordKey === highlightedRecordKey) {
                retval = true;
            }
        }
        return retval;
    }

    render() {
        const { classes, itemSchema, toggleAvailable, disabled } = this.props;

        return (
            <div className={classes.dividerLineTitle} disabled={disabled}>
                <div className={classes.gridHeader}>
                    {toggleAvailable ? <div className={classes.toggleButtonPlaceholder} /> : null}
                    <Typography align={'center'} color='inherit' variant='h6' className={classes.typographyFullWidth}>
                        {itemSchema.displayName}
                    </Typography>
                </div>
                {this.renderAddRowButton()}
                <div className={classes.tableWrapper} ref={this.scrollableDivRef}>
                    <Table size='small' className={classes.table} stickyHeader>
                        <TableHead>
                            <TableRow>
                                {itemSchema.columns?.map(column => {
                                    return (
                                        <TableCell key={column.fieldName} className={classes.headerCell}>
                                            {column.displayName ?? column.fieldName}
                                        </TableCell>
                                    );
                                })}
                                <TableCell className={classes.deleteHeaderCell}>
                                    Delete
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {this.renderTableBody()}
                    </Table>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(GridView);