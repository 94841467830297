import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import styled from "styled-components";

import LxCheckbox from '.../components/formComponents/LxCheckbox';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
    width: 85%;
    height: 15%;
    padding-left: 5%;

  input[type="color"] {
    // margin-right: 8px;
    -webkit-appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    background: none;
    &::-webkit-color-swatch-wrapper {
      padding: 0;
      width: 14px;
      height: 14px;
    }
    &::-webkit-color-swatch {
      border: 1px solid #bfc9d9;
      border-radius: 4px;
      padding: 0;
    }
  }

  input[type="text"] {
    border: none;
    width: 100%;
    font-size: 14px;
  }  
`;

const ColorPicker = props => {
    return (
        <Container>
            <input type="color" {...props} />
            <input type="text" {...props} value="Color" />
        </Container>
    );
};

const styles = theme => ({
    content: {
        margin: 'auto',
        overflow: 'hidden',
        float: 'left',
        container: 'flex',
        flexdirection: 'column',
        justifycontent: 'space-between'
    },
    formatButtons: {
        display: 'inline-flex'
        // justifycontent: 'flex-end'
    },
    propertyFields: {
        minWidth: '80px',
        paddingBottom: '20px'
    }
});

class LabelCustomizer extends React.Component {
    state = {
        style: {
            ...this.props.value
        }

    }

    componentDidMount() {
        let styleToUpdate = this.state.style;
        if (styleToUpdate !== null) {
            let localStyle = {
                'fontWeight': styleToUpdate['fontWeight'] === "False" ? false : styleToUpdate['fontWeight'],
                'textDecoration': styleToUpdate['textDecoration'] === "False" ? false : styleToUpdate['textDecoration'],
                'fontStyle': styleToUpdate['fontStyle'] === "False" ? false : styleToUpdate['fontStyle'],
                'outline': styleToUpdate['outline'] === "False" ? false : styleToUpdate['outline'],
                'color': styleToUpdate['color'],
                'textLineVisible': styleToUpdate['textLineVisible'] === false ? false : true
            };
            this.setState({
                style: localStyle
            });
        }
    }
    updateStyle = (propertyName) => (value) => {
        const { onChange } = this.props;

        let style = this.state.style;

        if (!style) {
            style = {
                'fontWeight': null,
                'textDecoration': null,
                'fontStyle': null,
                'outline': null,
                'color': '#000000',
                'textLineVisible': true
            };
        }

        let newStyle = {
            ...style
        };

        switch (propertyName) {
            case 'fontWeight':
                newStyle['fontWeight'] = value ? 'bold' : false;
                break;
            case 'textDecoration':
                newStyle['textDecoration'] = value ? 'underline' : false;
                break;
            case 'fontStyle':
                newStyle['fontStyle'] = value ? 'italic' : false;
                break;
            case 'outline':
                newStyle['outline'] = value ? 'solid black thin' : false;
                break;
            case 'color':
                newStyle['color'] = value;
                break;
            case 'textLineVisible':
                newStyle['textLineVisible'] = value ? true : false;
                break;
            default:
                break;
        }

        this.setState({
            style: newStyle
        });

        onChange(newStyle);
    }

    render() {
        const { classes } = this.props;
        let style = this.state.style;

        let formattingFields = [
            {
                name: "fontWeight",
                label: "Bold"
            },
            {
                name: "textDecoration",
                label: "Underline"
            },
            {
                name: "fontStyle",
                label: "Italicize"
            },
            {
                name: "outline",
                label: "Outline"
            },
            {
                name: "textLineVisible",
                label: "TextLineVisible"
            }
        ];

        return (
            <div className={classes.content}>
                <div className={classes.formatButtons}>

                    {formattingFields.map((field) => {

                        return (
                            <LxCheckbox
                                style={{ 'display': 'inline-block' }}
                                className={classes.propertyFields}
                                value={style[field.name]}
                                onChange={this.updateStyle(field.name)}
                                title={field.label}
                                disabled={false}
                                threeState={false}
                            />
                        );
                    })}

                    <Tooltip placement="bottom" title="To reset color to black, set all color parameters to 0, like this: rgb(0, 0, 0)." arrow>
                        <ColorPicker onChange={(e) => this.updateStyle('color')(e.target.value)} value={style.color} />
                    </Tooltip>

                </div>
            </div>
        );
    }
}

export default withStyles(styles)(LabelCustomizer);

