import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Button, Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import dotnetify from "dotnetify";
import React from 'react';
import { connect } from 'react-redux';
import {
    Prompt,
    withRouter
} from 'react-router';
import { compose } from "redux";

import ReportContainer from '../../../reports/ReportContainer';
import { ALLOW_ILLEGAL_DATA } from '.../components/settings/SettingKeys';
import userManager from '.../utils/userManager';
import StsSurgicalRiskRenderContainer from './StsSurgicalRiskRenderContainer';

dotnetify.hubServerUrl = window.env.LCDS_ROUTE;

const styles = theme => ({
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    },
    dialogPaper: {
        width: '100%'
    },
    backendActionBackdrop: {
        zIndex: '10000'
    },
});

class StsSurgicalRiskContainerSignalR extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copilotFormData: {},
            progress: 0,
            riskResult: [],
            illegalFieldsErrorMessageOpen: false,
            illegalFields: [],
            savedSnackbarOpen: false,
            moduleSecurity: {},
            isLockable: false,
            lockStateTarget: null,
            unsavedChanges: false,
            formSecurity: {},
            newEvent: this.props.match.params.recordId === '0',
            enableFormEdit: false,
            openStaffDialog: false,
            commandButtonSchema: null,
            openPopupDialog: false,
            popupSectionSchema: null,
            openReportDialog: false,
            openUnconfirmedEpicDataPopup: false,
            mappingElementsStatus: [],
            isFormDataSaving: false,
            showloader: false,
            showLoaderOnEnableEdit: false,
            mappedElements: []
        };

        dotnetify.connectionStateHandler = state => {
            if (state === 'reconnecting' || state === 'connected') {
                this.vm.$hub._connection.serverTimeoutInMilliseconds = 90000; // 60 seconds
                this.vm.$hub._connection.keepAliveIntervalInMilliseconds = 15000; // 15 seconds
            }
        };

        userManager.getUser().then(async (user) => {
            let headers = {
                Authorization: 'Bearer ' + user.access_token
            };
            // Connect this component to the back-end view model.
            this.vm = dotnetify.react.connect("RiskVM", this, {
                headers: headers,
                vmArg: {
                    SsPatientId: this.props.patientInfo.ssPatientId,
                    EmrPatients: this.props.patientInfo.emrPatients,
                    FormName: this.props.formName,
                    EventId: this.props.recordId
                }
            });

            this.vm.$hub._connection.serverTimeoutInMilliseconds = 90000; // 60 seconds
            this.vm.$hub._connection.keepAliveIntervalInMilliseconds = 15000; // 15 seconds

            // Set up function to dispatch state to the back-end.
            this.dispatchState = state => this.vm.$dispatch(state);

            userManager.events.addUserLoaded(() => {
                userManager.getUser().then(async (user) => {
                    this.vm.$dispatch({
                        $headers: {
                            Authorization: 'Bearer ' + user.access_token
                        }
                    });
                });
            });
        });
    }

    componentWillUnmount() {
        userManager.events.removeUserLoaded();
        this.vm.$destroy();
    }

    updateEvent = (dataPoints) => {
        const { unsavedChanges } = this.state;

        this.dispatchState({
            UpdateEvent: {
                DataPoints: dataPoints
            }
        });
        if (!unsavedChanges) {
            this.setState({
                unsavedChanges: true
            });
        }
    };

    handleChange = (dataPoints) => {
        this.updateEvent(dataPoints);
    };

    executeCommandButton = (commandButton) => {
        const { unsavedChanges } = this.state;

        if (commandButton.lookupName !== undefined && commandButton.lookupName !== '' && commandButton.lookupName !== null) {
            if (commandButton.lookupName === 'Staff') {
                this.setState({
                    openStaffDialog: true,
                    commandButtonSchema: commandButton
                });
            }
            else {
                alert('Please enter proper lookup name in CommandButton property.');
            }
        }
        else if (commandButton.popUpFormName !== undefined && commandButton.popUpFormName !== '' && commandButton.popUpFormName !== null) {
            this.onFormSectionChange(commandButton.popUpFormName);

            this.setState({
                popupSectionSchema: formSchema.sections.find(s => s.sectionName === commandButton.popUpFormName)
            }, () => this.setState({ openPopupDialog: true }));
        }
        else {
            this.dispatchState({
                ExecuteScript: commandButton
            });
            if (!unsavedChanges) {
                this.setState({
                    unsavedChanges: true
                });
            }
        }
    }

    closeStaffLookup = () => {
        this.setState({
            openStaffDialog: false
        });
    }

    closePopupForm = () => {
        this.setState({
            openPopupDialog: false
        });
    }

    handleBlur = (dataPoints) => {
        const { openStaffDialog, openPopupDialog } = this.state;

        if (openStaffDialog === true) {
            this.setState({
                openStaffDialog: false
            });
        }

        if (openPopupDialog === true) {
            this.setState({
                openPopupDialog: false
            });
        }

        this.updateEvent(dataPoints);
    };

    clearEvent = () => {
        this.setState({
            unsavedChanges: false
        });
        this.props.history.goBack();
    };

    getFormMappingElements = (mappingElements) => {
        const { progress } = this.state;
        if (progress === 100) {
            this.setState({
                mappedElements: mappingElements
            });
        }
    };

    saveFormBeforeSessionTimeout = () => {
        const { settings } = this.props;
        const { copilotFormData } = this.state;
        this.setState({
            isFormDataSaving: false,
            showloader: true
        });
        //const mappingElements = this.getFormMappingElements();

        let illegalFields = this.getIllegalFieldsList(copilotFormData);

        if (illegalFields.length === 0 || settings[ALLOW_ILLEGAL_DATA]) {
            this.setState({
                illegalFields: [],
                unsavedChanges: false,
                newEvent: this.props.recordId === '0' ? true : false
            });
            //let newMappingElementsStatus = Array.isArray(mappingElements) ? [...mappingElements] : [];
            //if (this.isListHasUnconfirmedStatus(newMappingElementsStatus)) {
            //  this.setState({
            //    openUnconfirmedEpicDataPopup: true
            //});
            //} else {
            this.saveConfirmDataFieldList();
            //}
        } else {
            this.showIllegalFieldsErrorMessage(illegalFields);
        }

        return true;
    };

    saveForm = (mappingElementsStatus) => () => {
        const { settings } = this.props;
        const { copilotFormData } = this.state;
        let allMappingElementsStatus = [...copilotFormData.formDemographicsCacheElements, ...copilotFormData.formCacheElements];
        const mappingElements = this.getMappedElements(allMappingElementsStatus);

        let illegalFields = this.getIllegalFieldsList(copilotFormData);

        if (illegalFields.length === 0 || settings[ALLOW_ILLEGAL_DATA]) {
            this.setState({
                illegalFields: [],
                unsavedChanges: false,
                newEvent: this.props.recordId === '0' ? true : false
            });
            let newMappingElementsStatus = Array.isArray(mappingElements) ? [...mappingElements] : [];
            if (this.isListHasUnconfirmedStatus(newMappingElementsStatus)) {
                this.setState({
                    openUnconfirmedEpicDataPopup: true
                });
            } else {
                this.saveConfirmDataFieldList();
            }
        } else {
            this.showIllegalFieldsErrorMessage(illegalFields);
        }
    };

    isListHasUnconfirmedStatus = (newMappingElementsStatus) => {
        let unconfirmedMappingElements = newMappingElementsStatus.filter(element => element.status === "Unconfirmed");
        if (unconfirmedMappingElements.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    saveConfirmDataFieldList = () => {
        this.setState({
            isFormDataSaving: false,
            showloader: true
        });
        const { copilotFormData } = this.state;
        let allMappingElementsStatus = [...copilotFormData.formDemographicsCacheElements, ...copilotFormData.formCacheElements];
        const mappingElements = this.getMappedElements(allMappingElementsStatus);

        let allMappedMissing = this.areAllMappedMissing(mappingElements);
        let isNoEmrOptionsForAllelements = this.isNoEmrOptionsForEveryElement(mappingElements);
        this.setState({
            illegalFields: [],
            unsavedChanges: false,
            newEvent: false
        });
        this.dispatchState({
            Save: {}
        });
        if (!isNoEmrOptionsForAllelements && !allMappedMissing) {
            this.dispatchState({
                AuditLogTheConfirmedFields: {}
            });
        }
        //this.showSavedSnackbar();
        this.setState({
            openUnconfirmedEpicDataPopup: false
        });
    };

    isNoEmrOptionsForEveryElement = (newMappingElementsStatus) => {
        let result = newMappingElementsStatus.every(element => {
            if (element.emrOptions !== null && element.emrOptions.length === 0) {
                return true;
            }
        });
        return result;
    };

    areAllMappedMissing = (mappingElementsStatusArg) => {
        let newMappingElementsStatus = Array.isArray(mappingElementsStatusArg) ? [...mappingElementsStatusArg] : [];
        const result = newMappingElementsStatus.every(element => {
            if (element.mappedStatus === 'Mapped-Missing' || element.mappedStatus === 'Mapping-Error') {
                return true;
            }
        });
        return result;
    }

    closeSavePopup = () => {
        const { unsavedChanges } = this.state;
        this.setState({
            openUnconfirmedEpicDataPopup: false
        });
        if (!unsavedChanges) {
            this.setState({
                unsavedChanges: true
            });
        }
    }

    getIllegalFieldsList = (copilotFormData) => {
        let illegalFields = [];
        if ((copilotFormData ?? null) !== null) {
            if (copilotFormData.validationList !== undefined) {
                copilotFormData.validationList.forEach((singleValidation) => {
                    switch (singleValidation.severity) {
                        case 1:
                            illegalFields.push(singleValidation);
                            break;
                        default:
                            break;
                    }
                });
            }
        }
        return illegalFields;
    };

    showIllegalFieldsErrorMessage = (illegalFields) => {
        this.setState({
            illegalFields: illegalFields,
            illegalFieldsErrorMessageOpen: true
        });
    };

    handleIllegalFieldsErrorMessageClose = () => {
        this.setState({
            illegalFieldsErrorMessageOpen: false
        });
    };

    showSavedSnackbar = () => {
        this.setState({
            savedSnackbarOpen: true
        });
    };

    handleSavedSnackbarClose = () => {
        this.setState({
            savedSnackbarOpen: false
        });
    };

    deleteRecord = () => {
        this.dispatchState({
            Delete: {}
        });
        this.props.history.push('/');
        this.props.history.push(`patient/${this.props.patientInfo.patientId}`);
    };

    toggleRecordLock = (targetState) => () => {
        const { unsavedChanges } = this.state;

        this.setState({
            lockStateTarget: targetState
        });

        if (unsavedChanges) {
            this.setState({
                unsavedChanges: false
            });
        }

        this.dispatchState({
            ToggleRecordLock: unsavedChanges
        });
    };

    onFormSectionChange = (sectionName) => {
        this.dispatchState({
            LoadFormSection: {
                SectionName: sectionName,
                IsPushUpdates: true
            }
        });
    };

    hideFormViewOnlyBar = () => {
        this.setState({
            enableFormEdit: true,
            showLoaderOnEnableEdit: true
        });
        this.dispatchState({
            enableFormEdit: true
        });
    };

    openReportPopup = () => {
        this.setState({
            openReportDialog: true
        });
    };

    closeReportPopup = () => {
        this.setState({
            openReportDialog: false
        });
    }

    onRecordLocked = (isRecordLocked) => {
        if (isRecordLocked) {
            this.dispatchState({
                ToggleRecordLock: false
            });
        }
    }

    updateEmrMappingStatus = (metaDataKey, conflicting, status, remark) => {
        const { mappingElementsStatus } = this.state;
        const { unsavedChanges, copilotFormData } = this.state;
        let allMappingElementsStatus = [...copilotFormData.formDemographicsCacheElements, ...copilotFormData.formCacheElements];
        const mappingElements = this.getMappedElements(allMappingElementsStatus);

        this.dispatchState({
            UpdateMappingStatus: {
                ElementName: metaDataKey,
                ConflictStatus: conflicting ? "Conflicting data" : "Nonconflicting data",
                ConfirmationStatus: status,
                Remark: remark
            }
        });
        let newMappingElementsStatus = Array.isArray(mappingElements) ? [...mappingElements] : [];
        let arrOfElementsInMappingElementsStatus = newMappingElementsStatus.filter((e) => (e.element === metaDataKey))
        let elementIndex;
        if (Array.isArray(arrOfElementsInMappingElementsStatus) && arrOfElementsInMappingElementsStatus.length > 0) {
            arrOfElementsInMappingElementsStatus.forEach(dupElement => {
                elementIndex = newMappingElementsStatus.map(element => element.element).indexOf(dupElement.element);
                if (dupElement.element === metaDataKey) {
                    dupElement.status = status
                }
                mappingElementsStatus.splice(elementIndex, 1, dupElement);
            });
        }

        if (!unsavedChanges) {
            this.setState({
                unsavedChanges: true
            });
        }
        this.setState({ mappingElementsStatus });
    }

    getMappedElements = (copilotFormData) => {
        const { mappedElements } = this.state;
        const _mappedElements = [];
        mappedElements.forEach(element => {
            let elementMatched = copilotFormData.find(mappingElement => mappingElement.element === element.element);
            if (elementMatched !== undefined) {
                _mappedElements.push(elementMatched);
            }
        });
        return _mappedElements;
    };

    render() {
        const { classes, formSchema, formMetaData, sectionList, settings, triggerScroll, recordId, patientInfo, sessionTimeout } = this.props;
        const {
            copilotFormData,
            progress,
            riskResult,
            illegalFieldsErrorMessageOpen,
            illegalFields,
            savedSnackbarOpen,
            moduleSecurity,
            isLockable,
            newEvent,
            lockStateTarget,
            unsavedChanges,
            formSecurity,
            enableFormEdit,
            openStaffDialog,
            commandButtonSchema,
            openPopupDialog,
            popupSectionSchema,
            openReportDialog,
            openUnconfirmedEpicDataPopup,
            copilotFormCacheData,
            mappingElementsStatus,
            isFormDataSaving,
            showloader,
            showLoaderOnEnableEdit
        } = this.state;

        if (!formSchema?.sections.length > 0) {
            return <CircularProgress size={60} thickness={7} />;
        }
        let ieMessage = window.IeVersion().IsIE ? "Warning, checking the don't let this page create more messages box will prevent you from navigating to other pages until you refresh the browser." : "";

        let message = `You have unsaved changes. If you select OK, you will lose your changes. Select Cancel to remain on the current page.\n\n${ieMessage}`;

        //This logic finds the head record associated with the form to determine if the record is locked or not. This is used to determine which text to display on the lock/unlock button
        let headRecord = {
            isLocked: false
        };
        let rootRecord = {
            isLocked: false
        };

        if (isFormDataSaving && showloader) {
            this.showSavedSnackbar();
            this.setState({
                showloader: false
            });
        }

        if (sessionTimeout === true) {
            if (unsavedChanges === true) {
                if (this.saveFormBeforeSessionTimeout()) {
                    console.log("Saved Successully");
                }
            }
        }

        if (progress === 0) {
            if (showLoaderOnEnableEdit) {
                this.setState({
                    showLoaderOnEnableEdit: false
                });
            }
        }

        if (copilotFormData && copilotFormData?.tables && formSchema) {
            let headTable = copilotFormData.tables[formSchema.header.eventTable];
            headRecord = headTable?.records[recordId] ? headTable?.records[recordId] : headRecord;
            if (lockStateTarget !== null) {
                if (lockStateTarget === headRecord?.isLocked) {
                    this.setState({
                        lockStateTarget: null
                    });
                }
            }
            let rootTable = copilotFormData.tables[copilotFormData.tables.Demographics.tableName];
            rootRecord = rootTable?.records[copilotFormData.globalContext.Demographics.key] ? rootTable?.records[copilotFormData.globalContext.Demographics.key] : rootRecord;
            rootRecord.isLocked = headRecord?.isLocked;
        }
        return (
            <React.Fragment>
                <Prompt
                    when={unsavedChanges}
                    message={message}
                />
                <StsSurgicalRiskRenderContainer
                    loadingResult={riskResult === null || riskResult.length === 0}
                    formSchema={formSchema}
                    formMetaData={formMetaData}
                    sectionList={sectionList}
                    formData={copilotFormData}
                    formResult={riskResult}
                    progress={progress}
                    unsavedChanges={unsavedChanges}
                    executeCommandButton={this.executeCommandButton}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    clearEvent={this.clearEvent}
                    saveForm={this.saveForm}
                    newEvent={newEvent}
                    illegalFieldsErrorMessageOpen={illegalFieldsErrorMessageOpen}
                    illegalFields={illegalFields}
                    handleIllegalFieldsErrorMessageClose={this.handleIllegalFieldsErrorMessageClose}
                    handleSavedSnackbarClose={this.handleSavedSnackbarClose}
                    savedSnackbarOpen={savedSnackbarOpen}
                    settings={settings}
                    moduleSecurity={moduleSecurity}
                    isLockable={isLockable}
                    toggleRecordLock={this.toggleRecordLock}
                    lockStateTarget={lockStateTarget}
                    headRecord={headRecord}
                    deleteRecord={this.deleteRecord}
                    triggerScroll={triggerScroll}
                    isLocked={headRecord.isLocked}
                    formSecurity={formSecurity}
                    enableFormEdit={enableFormEdit}
                    onHideFormViewOnlyBar={this.hideFormViewOnlyBar}
                    openStaffDialog={openStaffDialog}
                    commandButtonSchema={commandButtonSchema}
                    closeStaffLookup={this.closeStaffLookup}
                    openReportPopup={this.openReportPopup}
                    openPopupDialog={openPopupDialog}
                    popupSectionSchema={popupSectionSchema}
                    closePopupForm={this.closePopupForm}
                    copilotFormCacheData={copilotFormCacheData}
                    patientInfo={patientInfo}
                    updateEmrMappingStatus={this.updateEmrMappingStatus}
                    getMappingElements={progress === 100 ? this.getFormMappingElements : []}
                    mappingElements={progress === 100 ? this.getMappedElements([...copilotFormData.formDemographicsCacheElements, ...copilotFormData.formCacheElements]) : []}
                    onFormSectionChange={this.onFormSectionChange}
                />
                <Backdrop className={classes.backendActionBackdrop} open={showloader && !isFormDataSaving}>
                    <CircularProgress size={60} thickness={7} color='secondary' />
                </Backdrop>
                <Backdrop className={classes.backendActionBackdrop} open={showLoaderOnEnableEdit}>
                    <CircularProgress size={60} thickness={7} color='secondary' />
                </Backdrop>
                <Dialog open={openReportDialog} onClose={this.closeReportPopup} classes={{ paper: classes.dialogPaper }} maxWidth={'lg'} disableBackdropClick>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeReportPopup}
                    />
                    <DialogTitle>
                        <Typography className={classes.title} align='center' variant='h5'>
                            {'Reports'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <ReportContainer
                            eventTableName={formSchema.header.eventTable}
                            eventId={recordId}
                            formName={formSchema.header.formDisplayName}
                            onRecordLocked={this.onRecordLocked}
                        />
                    </DialogContent>
                    <MuiDialogActions>
                        <Button color="primary" variant='contained' onClick={this.closeReportPopup}>
                            Close
                        </Button>
                    </MuiDialogActions>
                </Dialog>
                <Dialog open={openUnconfirmedEpicDataPopup} onClose={this.closeSavePopup} classes={{ paper: classes.dialogPaper }} maxWidth={'md'} disableBackdropClick>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeSavePopup}
                    />
                    <DialogTitle className={classes.dialogTitle}>
                        {'Warning, Unconfirmed FHIR Data'}
                    </DialogTitle>
                    <DialogContent className={classes.EpicConfirmationDialogContent}>
                        {'There is Unconfirmed FHIR Data on this form. Fields with FHIR data must be manually confirmed before they can be saved. If you save the form now, no unconfirmed FHIR data will be saved to the database. Click Save to save fields without FHIR data and fields with confirmed FHIR data. Click cancel to return to the form.'}
                    </DialogContent>
                    <MuiDialogActions className={classes.dialogButtons}>
                        <Button variant='contained' color='primary' onClick={this.saveConfirmDataFieldList}>
                            {'Save'}
                        </Button>
                        <Button variant='contained' color='primary' onClick={this.closeSavePopup}>
                            {'Cancel'}
                        </Button>
                    </MuiDialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default compose(
    connect((state) => ({ patientInfo: state.demographics, sessionTimeout: state.sessionTimeout.value })),
    withRouter,
    withStyles(styles)
)(StsSurgicalRiskContainerSignalR);