import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import React from 'react';
import { connect } from 'react-redux';

import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import { emrMappingApi, pushToError } from '.../utils/apiHelper';
import FieldSelector from './FieldSelector';
import MappingEditor from './MappingEditor';
import MappingSelector from './MappingSelector';

const styles = theme => ({
    gridContainer: {
        height: 'inherit'
    },
    gridItem: {
        height: 'inherit'
    },
    closeIcon: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 10
    }
});

class MappingManagerContainer extends React.Component {
    state = {
        form: null,
        eventTable: null,
        view: null,
        formList: null,
        formListLoading: true,
        eventTableList: null,
        eventTableListLoading: true,
        viewList: null,
        viewListLoading: true,
        fieldList: null,
        fieldListLoading: true,
        selectedField: null,
        selectedFieldsLoading: false,
        codeDialogOpen: false,
        codesLoading: false,
        codeList: null,
        emrMappingPool: null,
        emrMappingPoolLoading: true,
        sourcesLoading: true
    }

    componentDidMount() {
        const { patientInfo } = this.props;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        emrMappingApi.getFormList().then(formList => {
            this.setState({
                formList,
                formListLoading: false
            });
        }).catch(error => pushToError(error, patientId));

        emrMappingApi.getEventTables().then(eventTables => {
            this.setState({
                eventTableList: eventTables.map(eventTable => ({ eventTable })),
                eventTableListLoading: false
            });
        }).catch(error => pushToError(error, patientId));

        emrMappingApi.getViewObjects().then(viewList => {
            this.setState({
                viewList,
                viewListLoading: false
            });
        }).catch(error => pushToError(error, patientId));

        emrMappingApi.getEmrSources().then(resourceResponse => {
            this.setState({
                sourceTypes: resourceResponse.sourceTypes,
                sources: resourceResponse.resources,
                sourcesLoading: false
            });
        }).catch(error => pushToError(error, patientId));

        this.getEmrMappings();
        this.getUpdatedFieldList();
    }

    getEmrMappings = () => {
        const { patientInfo } = this.props;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        this.setState({
            emrMappingPoolLoading: true
        });

        emrMappingApi.getEmrMappings().then(emrMappingPool => {
            this.setState({
                emrMappingPool,
                emrMappingPoolLoading: false
            });
        }).catch(error => pushToError(error, patientId));
    }

    getMappingElements = (selectedMapping) => {
        const { patientInfo } = this.props;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        emrMappingApi.getEmrMappingElements(selectedMapping.name).then(codeList => {
            this.setState({
                codeList,
                codesLoading: false
            });
        }).catch(error => pushToError(error, patientId));
    }

    handleMappingSelect = (selectedMapping) => {
        this.setState({
            selectedMapping,
            originalMappingName: selectedMapping?.name,
            codeDialogOpen: true,
            codesLoading: true
        });

        this.getMappingElements(selectedMapping);
    }

    createMapping = (name, sourceType, source) => {
        const { patientInfo } = this.props;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        let emrMappingDef = {
            name,
            mappingElements: null,
            expression: null,
            missingExpression: null,
            emrSource: {
                sourceType,
                source
            }
        };

        this.setState({
            selectedMapping: emrMappingDef,
            codeDialogOpen: true,
            codesLoading: true
        });

        if (this.hasRequiredMetaData(emrMappingDef)) {
            emrMappingApi.createEmrMapping(emrMappingDef).then(() => {
                this.handleMappingSelect(emrMappingDef);
                this.getEmrMappings();
                this.getMappingElements(emrMappingDef);
                this.setState({
                    originalMappingName: emrMappingDef.name
                });
            }).catch(error => pushToError(error, patientId));
        }
    }

    deleteMapping = (mappingName) => {
        const { patientInfo } = this.props;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        this.setState({
            emrMappingPoolLoading: true
        });

        emrMappingApi.deleteEmrMapping(mappingName).then(() => {
            this.getEmrMappings();
        }).catch(error => pushToError(error, patientId));
    }

    getUpdatedFieldList = (tableName = '', viewName = '', formName = '') => {
        const { patientInfo } = this.props;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;


        emrMappingApi.getApolloFields(tableName, viewName, formName).then(fieldList => {
            const { eventTable, view, form } = this.state;
            let currentFormName = form !== null ? form.formName : '';
            let currentViewName = view !== null ? view.viewName : '';
            let currentTableName = eventTable !== null ? eventTable.eventTable : '';

            if (formName === currentFormName && tableName === currentTableName && viewName === currentViewName) {
                this.setState({
                    fieldList,
                    fieldListLoading: false
                });
            }
        }).catch(error => pushToError(error, patientId));
    }

    handleFieldMappingChange = (emrMappings) => {
        const { selectedField, eventTable, view, form } = this.state;
        const { patientInfo } = this.props;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        if (selectedField !== null) {
            this.setState({
                selectedFieldsLoading: true
            });

            let currentView = view !== null ? view.viewName : '';

            emrMappingApi.updateEmrFieldMappings(selectedField.tableName, selectedField.fieldName, currentView, emrMappings).then(() => {
                this.getUpdatedFieldList(
                    eventTable !== null ? eventTable.eventTable : '',
                    view !== null ? view.viewName : '',
                    form !== null ? form.formName : ''
                );

                this.setState({
                    selectedFieldsLoading: false,
                    selectedField: {
                        ...selectedField,
                        emrMappings
                    }
                });
            }).catch(error => pushToError(error, patientId));
        }
    }

    handleMappingElementChange = (codeList) => {
        const { selectedMapping } = this.state;
        const { patientInfo } = this.props;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        this.setState({
            codesLoading: true
        });

        let selectedEmrMappingDef = {
            name: selectedMapping.name,
            mappingElements: codeList,
            emrSource: {
                sourceType: selectedMapping.emrSource.sourceType,
                source: selectedMapping.emrSource.source
            }
        };

        let emrMappingDef = {
            ...selectedMapping,
            mappingElements: codeList
        };

        if (this.hasRequiredMetaData(emrMappingDef)) {
            emrMappingApi.updateEmrMapping(emrMappingDef.name, selectedEmrMappingDef).then(() => {
                this.getMappingElements(selectedMapping);
            }).catch(error => pushToError(error, patientId));
        }
    }

    handleMappingMetadataChange = (newMapping) => {
        const { originalMappingName, codeList, emrMappingPool } = this.state;
        const { patientInfo } = this.props;
        let patientId = patientInfo === null ? undefined : patientInfo.patientId;

        this.setState({
            selectedMapping: newMapping
        });
        
        let emrMappingDef = {
            name: newMapping.name,
            mappingElements: codeList,
            expression: newMapping.expression,
            missingExpression: newMapping.missingExpression,
            emrSource: {
                sourceType: newMapping.emrSource.sourceType,
                source: newMapping.emrSource.source
            }
        };

        if (this.hasRequiredMetaData(emrMappingDef)) {
            let emrMappings = Array.isArray(emrMappingPool) ? [...emrMappingPool] : [];            
            let mappingName = newMapping.name;
        
            let invalidName = false;
            let invalidMappingName = emrMappings.filter(emrMapping => emrMapping.name.trim() === mappingName.trim());
      
            if (invalidMappingName.length > 0) 
            {
                invalidName =  true;
            } else
            {
                invalidName = false;
            }
            if(invalidName === false)
            {
                emrMappingApi.updateEmrMapping(originalMappingName, emrMappingDef).then(() => {
                this.getEmrMappings();
                this.getMappingElements(newMapping);
                this.setState({
                    originalMappingName: newMapping.name
                });
                }).catch(error => pushToError(error, patientId));
            }
            else
            {
                alert("Mapping Name already exists.");
            }            
        }
    }

    hasRequiredMetaData(mapping) {
        if ((mapping?.name ?? '') === ''
            || (mapping?.emrSource?.sourceType ?? '') === ''
            || (mapping?.emrSource?.source ?? '') === '') {
            return false;
        }
        return true;
    }

    handleFieldFilterChange = (eventTable, view, form) => {
        this.setState({
            eventTable,
            view,
            form
        });

        this.setState({
            fieldListLoading: true,
            selectedField: null
        });

        this.getUpdatedFieldList(
            eventTable !== null ? eventTable.eventTable : '',
            view !== null ? view.viewName : '',
            form !== null ? form.formName : ''
        );
    }

    handleFieldSelect = (selectedField) => {
        this.setState({
            selectedField
        });
    }

    closeDialog = () => {
        this.setState({
            codeDialogOpen: false,
            codesLoading: false,
            codeList: null,
            selectedMapping: null,
            originalMappingName: null
        });
    }


    render() {
        const { classes } = this.props;
        const {
            form,
            eventTable,
            view,
            formListLoading,
            formList,
            eventTableListLoading,
            eventTableList,
            viewListLoading,
            viewList,
            fieldList,
            fieldListLoading,
            selectedField,
            selectedFieldsLoading,
            emrMappingPool,
            emrMappingPoolLoading,
            selectedMapping,
            originalMappingName,
            codeDialogOpen,
            codesLoading,
            codeList,
            sourceTypes,
            sourcesLoading,
            sources
        } = this.state;

        return (
            <React.Fragment>
                <GridContainer spacing={4} className={classes.gridContainer}>
                    <GridItem className={classes.gridItem} xs={6}>
                        <FieldSelector
                            form={form}
                            eventTable={eventTable}
                            view={view}
                            formListLoading={formListLoading}
                            formList={formList}
                            eventTableListLoading={eventTableListLoading}
                            eventTableList={eventTableList}
                            viewListLoading={viewListLoading}
                            viewList={viewList}
                            fieldList={fieldList}
                            fieldListLoading={fieldListLoading}
                            onFieldFilterChange={this.handleFieldFilterChange}
                            onFieldSelect={this.handleFieldSelect}
                            selectedField={selectedField}
                        />
                    </GridItem>
                    <GridItem className={classes.gridItem} xs={6}>
                        <MappingSelector
                            selectedField={selectedField}
                            selectedFieldsLoading={selectedFieldsLoading || sourcesLoading}
                            onFieldMappingChange={this.handleFieldMappingChange}
                            emrMappingPool={emrMappingPool}
                            emrMappingPoolLoading={emrMappingPoolLoading || sourcesLoading}
                            selectedMapping={selectedMapping}
                            onMappingSelect={this.handleMappingSelect}
                            createMapping={this.createMapping}
                            deleteMapping={this.deleteMapping}
                            sourceTypes={sourceTypes}
                            sources={sources}
                        />
                    </GridItem>
                </GridContainer>
                <Dialog open={codeDialogOpen} onClose={this.closeDialog} maxWidth={false}>
                    <Close
                        className={classes.closeIcon}
                        onClick={this.closeDialog}
                    />
                    <MappingEditor
                        selectedMapping={selectedMapping}
                        originalMappingName={originalMappingName}
                        onMappingElementChange={this.handleMappingElementChange}
                        onMappingMetadataChange={this.handleMappingMetadataChange}
                        sourceTypes={sourceTypes}
                        sources={sources}
                        emrMappingPool={emrMappingPool}
                        codesLoading={codesLoading}
                        codeList={codeList}
                    />
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(connect((state) => ({ patientInfo: state.demographics }))(MappingManagerContainer));
