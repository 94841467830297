import { formApi } from '.../utils/apiHelper';

const GetMiniString = inputString => {
    return inputString.split(' ').map(word => {
        if (typeof word === 'string' && word[0]?.toLowerCase() !== word[0]?.toUpperCase()) {
            return word[0]?.toUpperCase();
        } else {
            return null;
        }
    }).join('');
};

const GetFormViews = (showForms, recordTable, recordId, formList) => {
    let views = [];

    if (showForms === true) {
        if(formList !== null)
        {
            let filteredFormList = formList.filter(x => x.eventTable === recordTable);
            filteredFormList.forEach(form => {
                views.push({
                    path: `/patient/:mrn/forms/${recordTable}/${recordId}/${form.formName}`,
                    name: form.formDisplayName,
                    mini: GetMiniString(form.formDisplayName),
                    link: true
                });
            });
        }
        else
        {
            views = innerGetFormViews(showForms, recordTable, recordId);
        }
        
    }

    return views;
};

const innerGetFormViews = (showForms, recordTable, recordId) => new Promise((resolve, reject) => {
    let views = [];

    formApi.getFormList().then(formList => {
        if (showForms === true) {
            let filteredFormList = formList.filter(x => x.eventTable === recordTable);
            filteredFormList.forEach(form => {
                views.push({
                    path: `/patient/:mrn/forms/${recordTable}/${recordId}/${form.formName}`,
                    name: form.formDisplayName,
                    mini: GetMiniString(form.formDisplayName),
                    link: true
                });
            });
        }
        resolve(views);
    }).catch(reject);
});

export default GetFormViews;
