import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import withStyles from '@material-ui/styles/withStyles';
import React from 'react';

import Card from '.../assets/components/Card/Card';
import CardBody from '.../assets/components/Card/CardBody';
import CardFooter from '.../assets/components/Card/CardFooter';
import CardHeader from '.../assets/components/Card/CardHeader';
import ConfirmationButton from '.../assets/components/CustomButtons/ConfirmationButton';
import GridContainer from '.../assets/components/Grid/GridContainer';
import GridItem from '.../assets/components/Grid/GridItem';
import LxSearchableTable from '.../components/formComponents/LxSearchableTable';
import LxSelectField from '.../components/formComponents/LxSelectField';
import LxTextField from '.../components/formComponents/LxTextField';
import ExpressionEditor from './ExpressionEditor';

const styles = theme => ({
    card: {
        width: '80vw',
        height: '80vh'
    },
    cardFooter: {
        display: 'flex'
    },
    bufferedDivider: {
        marginTop: '20px'
    },
    dropdownPaper: {
        maxHeight: '450px'
    },
    dropdownRoot: {
        zIndex: '2100 !important'
    },
    flexBox: {
        marginTop: '25px'
    },
    table: {
        flex: '1 0 auto'
    },
    addCode: {
        width: '100%'
    },
    buttonCell: {
        padding: '0px !important'
    },
    button: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        height: '24px !important',
        display: 'flex'
    },
    expressionText: {
        flex: '1 1 auto',
        width: 'auto !important',
        textAlign: 'left !important',
        paddingRight: '12px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'none'
    },
    expressionIcon: {
        flex: '0 0 auto'
    }
});

class MappingEditor extends React.Component {
    state = {
        sourceNameDisabled: (this.props.selectedMapping?.emrSource?.sourceType ?? '') === '',
        newCodeCount: 0,
        expressionEditorElement: null,
        expressionEditorOpen: false
    };

    newCodeName = ''
    newCodeSystem = ''
    newCodeValue = ''

    //#region Metadata Functions
    handleNameChange = (newName) => {
        const { selectedMapping, onMappingMetadataChange } = this.props;
        if (selectedMapping?.name !== newName) {
            let newMapping = {
                ...selectedMapping,
                name: newName
            };
            onMappingMetadataChange(newMapping, selectedMapping.name);
        }
    }

    handleSourceTypeChange = (newSourceType) => {
        const { selectedMapping, onMappingMetadataChange } = this.props;
        if (selectedMapping?.emrSource?.sourceType !== newSourceType) {
            let newMapping = {
                ...selectedMapping,
                emrSource: {
                    ...selectedMapping.emrSource,
                    sourceType: newSourceType
                }
            };
            onMappingMetadataChange(newMapping, selectedMapping.name);
        }
    }

    handleSourceChange = (newSource) => {
        const { selectedMapping, onMappingMetadataChange } = this.props;
        if (selectedMapping?.emrSource?.source !== newSource) {
            let newMapping = {
                ...selectedMapping,
                emrSource: {
                    ...selectedMapping.emrSource,
                    source: newSource
                }
            };
            onMappingMetadataChange(newMapping, selectedMapping.name);
        }
    }

    validateMapping = () => {
        const { emrMappingPool, selectedMapping, originalMappingName } = this.props;
        let invalidMappingName = false;
        let invalidMappingNameMessage = '';
        let invalidMappingSourceType = false;
        let invalidMappingSourceTypeMessage = '';
        let invalidMappingSource = false;
        let invalidMappingSourceMessage = '';
        if (selectedMapping !== null) {
            if ((selectedMapping.name ?? '') === '') {
                invalidMappingName = true;
                invalidMappingNameMessage = 'Name is a required field';
            } else if (originalMappingName !== selectedMapping.name && Array.isArray(emrMappingPool)) {
                emrMappingPool.forEach(mapping => {
                    if (mapping.name === selectedMapping.name) {
                        invalidMappingName = true;
                        invalidMappingNameMessage = 'There is already a mapping with this name';
                    }
                });
            }

            if ((selectedMapping?.emrSource.sourceType ?? '') === '') {
                invalidMappingSourceType = true;
                invalidMappingSourceTypeMessage = 'Source Type is a required field';
            }

            if ((selectedMapping?.emrSource.source ?? '') === '') {
                invalidMappingSource = true;
                invalidMappingSourceMessage = 'Source is a required field';
            }
        }
        let metadataValidation = {
            invalidMappingName,
            invalidMappingNameMessage,
            invalidMappingSourceType,
            invalidMappingSourceTypeMessage,
            invalidMappingSource,
            invalidMappingSourceMessage
        };
        return metadataValidation;
    }
    //#endregion

    //#region Element Selector Functions
    createCode = () => {
        const { newCodeCount } = this.state;
        const { codeList, onMappingElementChange } = this.props;
        let invalidElement = false;
        let newCodes = Array.isArray(codeList) ? [...codeList] : [];
        let newCodeName = this.newCodeName;
        let newCodeSystem = this.newCodeSystem;
        let newCodeValue = this.newCodeValue;

        let invalidMappingElements = newCodes.filter(newCode => newCode.name.trim() === newCodeName.trim() && newCode.codeSystem.trim() === newCodeSystem.trim() && newCode.codeValue.trim() === newCodeValue.trim());
        if (invalidMappingElements.length > 0) {
            invalidElement =  true;
        } else {
            invalidElement = false;
        }
        
        if(invalidElement === false)
        {
            newCodes.push({
            name: this.newCodeName,
            codeSystem: this.newCodeSystem,
            codeValue: this.newCodeValue,
            criteriaExpression: ''
            });

            if (typeof onMappingElementChange === 'function') {
            onMappingElementChange(newCodes);
            }

            this.setState({
            newCodeCount: newCodeCount + 1
            });

            this.newCodeName = '';
            this.newCodeSystem = '';
            this.newCodeValue = '';
        }
        else
        {
            alert("Mapping Element Name with same Code System and code already exists.");
        }
        
    }

    onCodeNameChange = (newCodeName) => {
        this.newCodeName = newCodeName;
    }

    onCodeSystemChange = (newCodeSystem) => {
        this.newCodeSystem = newCodeSystem;
    }

    onCodeValueChange = (newCodeValue) => {
        this.newCodeValue = newCodeValue;
    }

    deleteCode = (deletedCode) => () => {
        const { codeList, onMappingElementChange } = this.props;

        let newCodes = Array.isArray(codeList) ? [...codeList] : [];
        let oldElementIndex = newCodes.map(code => code.id).indexOf(deletedCode.id);
        newCodes.splice(oldElementIndex, 1);
        if (typeof onMappingElementChange === 'function') {
            onMappingElementChange(newCodes);
        }
    }
    //#endregion

    //#region Expression Functions
    handleEditExpression = (expressionEditorElement) => (event) => {
        event.preventDefault();

        this.setState({
            expressionEditorElement,
            expressionEditorOpen: true
        });
    }

    updateElementExpression = (criteriaExpression) => {
        const { expressionEditorElement } = this.state;
        const { codeList, onMappingElementChange } = this.props;

        let newCodes = Array.isArray(codeList) ? [...codeList] : [];
        let updatedCode = {
            ...expressionEditorElement,
            criteriaExpression
        };

        let oldElementIndex = newCodes.map(code => code.id).indexOf(expressionEditorElement.id);

        newCodes.splice(oldElementIndex, 1, updatedCode);

        if (typeof onMappingElementChange === 'function') {
            onMappingElementChange(newCodes);
        }
    }

    closeExpressionEditor = () => {
        this.setState({
            expressionEditorElement: null,
            expressionEditorOpen: false
        });
    }
    //#endregion

    //#region Render Functions
    renderMappingMetadataEditor = () => {
        const { classes, sourceTypes, sources, selectedMapping } = this.props;
        const { sourceNameDisabled } = this.state;

        let validationMetaData = this.validateMapping();
        return (
            <GridContainer className={classes.flexBox} spacing={5}>
                <GridItem xs={6}>
                    <LxTextField
                        align='center'
                        title='Mapping Name'
                        defaultValue={typeof selectedMapping?.name !== 'undefined' && selectedMapping?.name !== null ?
                            `${selectedMapping.name}`
                            :
                            ''}
                        onBlur={this.handleNameChange}
                        error={validationMetaData.invalidMappingName}
                        helperText={validationMetaData.invalidMappingNameMessage}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='Source Type'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={selectedMapping?.emrSource?.sourceType}
                        onChange={this.handleSourceTypeChange}
                        options={sourceTypes}
                        nullable={false}
                        error={validationMetaData.invalidMappingSourceType}
                        helperText={validationMetaData.invalidMappingSourceTypeMessage}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxSelectField
                        title='Source'
                        classes={{
                            dropdownRoot: classes.dropdownRoot,
                            dropdownPaper: classes.dropdownPaper
                        }}
                        value={selectedMapping?.emrSource?.source}
                        onChange={this.handleSourceChange}
                        options={!sourceNameDisabled ? sources[selectedMapping?.emrSource?.sourceType.toLowerCase()] : []}
                        disabled={sourceNameDisabled}
                        nullable={false}
                        error={validationMetaData.invalidMappingSource}
                        helperText={validationMetaData.invalidMappingSourceMessage}
                    />
                </GridItem>
            </GridContainer>
        );
    }

    renderElementTable = () => {
        const { classes, codesLoading, codeList } = this.props;

        return (
            <LxSearchableTable
                title='Elements'
                className={classes.table}
                items={codeList}
                isLoading={codesLoading}
                columns={[
                    {
                        title: 'Element Name',
                        key: 'name'
                    },
                    {
                        title: 'Code System',
                        key: 'codeSystem'
                    },
                    {
                        title: 'Code',
                        key: 'codeValue'
                    },
                    {
                        id: 'expression',
                        width: 200,
                        title: 'Criteria Expression',
                        className: classes.buttonCell,
                        key: (item) => (
                            <ConfirmationButton
                                onClick={this.handleEditExpression(item)}
                                className={classes.button}
                                error={
                                    typeof item.criteriaExpression === 'undefined'
                                    || item.criteriaExpression === ''
                                    || item.criteriaExpression === null
                                }
                            >
                                <div className={classes.expressionText}>
                                    {`${item.criteriaExpression}`}
                                </div>
                                <EditIcon className={classes.expressionIcon} />
                            </ConfirmationButton>
                        )
                    },
                    {
                        id: 'delete',
                        width: 75,
                        resizable: false,
                        className: classes.buttonCell,
                        title: 'Delete',
                        key: (item) => (
                            <ConfirmationButton
                                className={classes.button}
                                showConfirmation
                                confirmationTitle='Permanently Delete Code?'
                                confirmationText={[
                                    'Are you sure you want to remove this code?',
                                    'You will not be able to get it back.'
                                ]}
                                confirmationConfirmText='Delete'
                                onClick={this.deleteCode(item)}
                            >
                                <DeleteIcon />
                            </ConfirmationButton>
                        )
                    }
                ]}
                searchKeys={[
                    'name',
                    'codeSystem',
                    'codeValue',
                    'criteriaExpression'
                ]}
            />
        );
    }

    renderElementFooter = () => {
        const { classes, codesLoading} = this.props;
        const { newCodeCount} = this.state;

        return (
            <GridContainer spacing={5}>
                <GridItem xs={3}>
                    <LxTextField
                        key={`CodeName-${newCodeCount}`}
                        placeholder='New Element Name...'
                        onChange={this.onCodeNameChange}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        key={`CodeSystem-${newCodeCount}`}
                        placeholder='New Code System...'
                        onChange={this.onCodeSystemChange}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <LxTextField
                        key={`CodeValue-${newCodeCount}`}
                        placeholder='New Code...'
                        onChange={this.onCodeValueChange}
                    />
                </GridItem>
                <GridItem xs={3}>
                    <Button
                        className={classes.addCode}
                        variant='outlined'
                        color='primary'
                        onClick={this.createCode}
                        disabled={codesLoading}
                    >
                        {'Add New Element'}
                    </Button>
                </GridItem>
            </GridContainer>
        );
    }
    //#endregion

    render() {
        const { classes } = this.props;
        const { expressionEditorOpen, expressionEditorElement } = this.state;

        return (
            <Card className={classes.card}>
                <CardHeader>
                    <GridContainer direction='column'>
                        <GridItem>
                            <Typography className={classes.title} align='center' variant='h5'>
                                {'Mapping Editor'}
                            </Typography>
                            <Divider className={classes.bufferedDivider}/>
                        </GridItem>
                        <GridItem>
                            {this.renderMappingMetadataEditor()}
                            <Divider className={classes.bufferedDivider}/>
                        </GridItem>
                    </GridContainer>
                </CardHeader>
                <CardBody>
                    {this.renderElementTable()}
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                    {this.renderElementFooter()}
                </CardFooter>
                <ExpressionEditor
                    isOpen={expressionEditorOpen}
                    closeDialog={this.closeExpressionEditor}
                    title='Criteria Expression Editor'
                    expression={expressionEditorElement?.criteriaExpression ?? ''}
                    onConfirm={this.updateElementExpression}
                />
            </Card>
        );
    }
}

export default withStyles(styles)(MappingEditor);

