import {useState } from 'react';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js';
import { auditApi } from '../utils/apiHelper';
import { sessionStorageHelper } from '../utils/sessionStorageHelper';
import userManager from '../utils/userManager';
import { useDispatch } from 'react-redux';
import { changeState } from '../reducer/SessionTimoutSlice';


export default function useIdle({
    //ADD THE onIdle AND idleTime props
    onIdle,
    idleTime,
    autologoutEnable,
    promptTime
}){
    const dispatch = useDispatch();
    const idleTimeout = 1000 * 60 * idleTime;
    const promptIdleTimeout = 1000 * (promptTime);
    const [isIdle, setIsIdle] = useState(false);

    const onLogout = () => {
        dispatch(changeState());
        //set isSigningOut cookie to true to disable auto redirect
        sessionStorageHelper.setIsSigningOut(true);
        userManager.getUser().then((user) => {
            userManager.signoutRedirect({ 'id_token_hint': user.id_token });
            userManager.removeUser(); // removes the user data from sessionStorage
        });
        auditApi.userlogout();
    };

    const handleOnIdle = event => {
        if (autologoutEnable) {
            setIsIdle(true);
            onLogout();
        }
        else {
            setIsIdle(false);
        }
    };

    const handleOnActive = () => {
        // Logic to execute when the user becomes active again
        setIsIdle(false);
        //activate();
      };

    const { reset, getRemainingTime, activate, getLastActiveTime } = useIdleTimer({
        timeout: idleTimeout,
        promptBeforeIdle: promptIdleTimeout,
        onPrompt: onIdle,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        events: ['keydown', 'mousedown']
    });

    //const idleTimer = useIdleTimer({
     //   timeout: idleTimeout,
       // promptBeforeIdle: idleTimeout / 2,
       // onPrompt: onIdle,
      //  onIdle: handleOnIdle,
      //  debounce: 500
   // })

    return {
        getRemainingTime,
        getLastActiveTime,
        isIdle,
        onIdle,
        reset,
        activate
    };
}